<template lang="pug">
  editor(
    api-key="iw8wenja29qsjj9dw1eigblky6lbj5el8e6ulw5f30zqabbh"
    :init="config"
    :value="value"
    @input="$emit('input', $event)"
  )
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import setup from "./setup";
import upload from "./upload";

export default {
  props: {
    value: String
  },
  components: {
    editor: Editor
  },
  data() {
    return {
      config: {
        height: 350,
        menubar: false,
        plugins: [
          "advlist autolink lists link image imagetools charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
            "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | link image | code",
        automatic_uploads: true,
        file_picker_callback: setup,
        images_upload_handler: upload,
        language: "pl"
      }
    };
  }
};
</script>
