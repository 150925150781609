export default {
  namespaced: true,
  state: {
    loading: true
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    endLoading(state) {
      state.loading = false;
    }
  },
  getters: {
    loading: state => state.loading,
    formatLength: (state, getters, rootState) => length => {
      if(length === 0 ) return "-"
      if (rootState.company.lengthUnit === "cm") {
        return getters.formatNumber((length / 10).toFixed(2));
      }
      return length;
    },
    formatNumber: () => number => {
      return number.toString().replace(".", ",");
    },
    formatPrice: () => cents => {
      return (Math.round(cents) / 100)
        .toFixed(2)
        .toString()
        .replace(".", ",");
    },
    formatWeight: () => grams => {
      return (Math.round(grams) / 1000)
          .toFixed(2)
          .toString()
          .replace(".", ",");
    },
    formatBoolean: () => value => {
      return value ? "TAK" : "NIE";
    },
    formatSurface:()=> surface =>{
      return (Math.round(surface) / 1000000)
          .toFixed(2)
          .toString()
          .replace(".", ",");
    }
  }
};
