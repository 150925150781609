<template lang="pug">
  b-modal(v-model="isActive" @close="onClose" style="max-width:100%")
    app-view-loadable(:loading="loading")
      app-view-card(:title="$t('order_preview' + (priceType ? `_${priceType}` : ``))")
        #order.container.print(v-if="this.object")
          .has-text-centered
            .has-margin-bottom {{object && object.status === 'valuation' ? $t('order_print.valuation') : $t('order_print.order') }} nr {{ getOrderNumber(object) }}
          .columns
            .column
              app-panel(v-if="priceType !== 'purchase'" :title="$t('order_print.purchaser')")
                app-panel-block(v-if="getCustomerField(object, 'companyName')") {{ getCustomerField(object, "companyName") || "" }}
                app-panel-block(v-if="getCustomerField(object, 'taxId')") {{ getCustomerField(object, "taxId") || "" }}
                app-panel-block(v-if="getCustomerField(object, 'firstName') || getCustomerField(object, 'lastName')") {{ getCustomerField(object, "firstName") || "" }} {{ getCustomerField(object, "lastName") || "" }}
                app-panel-block(v-if="getCustomerField(object, 'zipCode') || getCustomerField(object, 'city') || getCustomerField(object, 'streetAndNumber')") {{ getCustomerField(object, "zipCode") || "" }} {{ getCustomerField(object, "city") || "" }} {{ getCustomerField(object, "streetAndNumber") || "" }}
                app-panel-block(v-if="getCustomerField(object, 'phone')") {{ getCustomerField(object, "phone") || "" }}

              app-panel(v-if="priceType !== 'sales'" :title="$t('order_print.purchaser')")
                app-panel-block(v-if="getValueFromCompanyType(object, 'name')") {{ getValueFromCompanyType(object, "name") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'zipCode') || getValueFromCompanyType(object, 'city') || getValueFromCompanyType(object, 'streetAndNumber')") {{ getValueFromCompanyType(object, "zipCode") }} {{ getValueFromCompanyType(object, "city") }} {{ getValueFromCompanyType(object, "streetAndNumber") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'taxId')") {{ getValueFromCompanyType(object, "taxId") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'phone')") {{ getValueFromCompanyType(object, "phone") }}

            .column
            .column
              app-panel(v-if="priceType !== 'purchase'" :title="$t('order_print.seller')")
                app-panel-block(v-if="getValueFromCompanyType(object, 'name')") {{ getValueFromCompanyType(object, "name") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'zipCode') || getValueFromCompanyType(object, 'city') || getValueFromCompanyType(object, 'streetAndNumber')") {{ getValueFromCompanyType(object, "zipCode") }} {{ getValueFromCompanyType(object, "city") }} {{ getValueFromCompanyType(object, "streetAndNumber") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'taxId')") {{ getValueFromCompanyType(object, "taxId") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'phone')") {{ getValueFromCompanyType(object, "phone") }}

              app-panel(v-if="priceType !== 'sales' && !isSubCompany(object)" :title="$t('order_print.seller')")
                app-panel-block.small-text AMIDEX Alina i Ireneusz Cieśla Sp. J
                app-panel-block.small-text 57-300 Kłodzko, ul. Objazdowa 3d
                app-panel-block.small-text NIP: 8831009048

              app-panel(v-if="priceType !== 'sales' && isSubCompany(object)" :title="$t('order_print.seller')")
                app-panel-block(v-if="getValueFromCompanyType(object, 'name','company')") {{ getValueFromCompanyType(object, "name", "company") }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'zipCode','company') || getValueFromCompanyType(object, 'city','company') || getValueFromCompanyType(object, 'streetAndNumber','company')") {{ getValueFromCompanyType(object, "zipCode",'company') }} {{ getValueFromCompanyType(object, "city",'company') }} {{ getValueFromCompanyType(object, "streetAndNumber",'company') }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'taxId','company')") {{ getValueFromCompanyType(object, "taxId",'company') }}
                app-panel-block(v-if="getValueFromCompanyType(object, 'phone','company')") {{ getValueFromCompanyType(object, "phone",'company') }}


          .has-text-centered
            .has-margin-bottom {{positionsTitle(object)}}
          external-shutter-order-items-after-sum(v-if="object.category === 'external_shutter'" :external-shutter-order-items="orderItems()" :price-type="priceType" :print-type="printType")
          external-shutter-order-items(v-if="object.category === 'external_shutter'" :external-shutter-order-items="orderItems()" :price-type="priceType" :print-type="printType")
          fabric-shade-order-items(v-if="object.category === 'fabric_shade'" :fabric-shade-order-items="orderItems()" :price-type="priceType" :print-type="printType")
          fabric-shade-day-night-order-items(v-if="object.category === 'fabric_shade_day_night'" :fabric-shade-day-night-order-items="orderItems()" :price-type="priceType" :print-type="printType")
          automation-order-items(v-if="object.category !== 'detail'" :automation-order-items="orderItems(true)" :price-type="priceType")
          pleat-order-items(v-if="object.category === 'pleat'" :pleat-order-items="orderItems()" :price-type="priceType" :print-type="printType")
          detail-order-items(v-if="object.category === 'detail'" :detail-order-items="orderItems()" :price-type="priceType" :print-type="printType")

          .columns
            .column.is-4
              app-panel(:title="$t('order_print.order_info')")
                table.table.is-bordered.has-margin-bottom.is-narrow
                  tbody
                    tr
                      th {{ $t('order_print.valuation_date') }}
                      td {{ prop(object, "valuationDate") || "" }}
                    tr
                      th {{ $t('order_print.order_date') }}
                      td {{ prop(object, "orderDate") || "" }}
                    tr
                      th {{ $t('order_print.taking_person') }}
                      td {{ fullName }}
                    tr(v-if="priceType !== 'sales'")
                      th {{$t('payment_method')}}
                      td {{ prop(object.paymentMethod, "name") }}
                    tr
                      th {{ $t('order_print.comments') }}
                      td {{ $t('order_print.comments_body') }}
            .column
            .column.is-6
              app-panel( v-if="priceType !== 'purchase'" :title="$t('order_print.summary')")
                table.table.is-bordered.has-margin-bottom.is-fullwidth.is-narrow
                  tbody
                    tr(v-if=" printType === 'full'")
                      th(colspan=2) Wartość netto
                      td.has-text-right {{ decimal(object.netSalesValue)}}
                    tr(v-if="printType === 'full'")
                      th(colspan=2) Wartość netto automatyki
                      td.has-text-right {{ decimal(object.netSalesAutomationValue)}}
                    tr
                      th {{ $t('discount') }}
                      td.has-text-right {{decimal(object.percentDiscount) }} %
                      td.has-text-right {{ decimal(object.discountValue) }}
                    tr(v-if="printType === 'full'")
                      th {{ $t('discount_automation') }}
                      td.has-text-right {{decimal(object.percentDiscountAutomation) }} %
                      td.has-text-right {{ decimal(object.discountAutomationValue) }}
                    tr
                      th(colspan="2") {{ $t('net_value_after_discount') }}
                      td.has-text-right {{ decimal(object.netSalesValueAfterDiscount) }}
                    tr(v-if="printType === 'full'")
                      th(colspan=2) {{ $t('net_value_automation_after_discount') }}
                      td.has-text-right {{ decimal(object.netSalesAutomationValueAfterDiscount) }}


                    tr(v-if="printType === 'full'")
                      th(colspan=2) {{ $t('order_print.installation') }}
                      td.has-text-right  {{ decimal(prop(object, "installationValue")) }}
                    tr(v-if="printType === 'full'")
                      th(colspan=2) {{ $t('order_print.approach') }}
                      td.has-text-right  {{ decimal(prop(object, "fitterTravelCostValue")) }}
                    tr(v-if="")
                      th(colspan=2) {{ $t('order_print.sum_net_value_after_discount') }}
                      td.has-text-right  {{ decimal(prop(object, "totalSalesNetValue")) }}
                    tr
                      th {{ $t('order_print.vat') }}
                      td.has-text-right  {{ prop(object, "companyVatRate", "name") }}
                      td.has-text-right  {{ decimal(prop(object, "salesVatRateValue")) }}
                    tr
                      th(colspan=2) {{ $t('order_print.gross_value') }}
                      td.has-text-right  {{ decimal(prop(object, "salesGrossValue")) }}
                    tr
                      th(colspan=2) {{ $t('order_print.advance') }}
                      td.has-text-right  {{ decimal(prop(object, "advanceValue")) }}
                    tr
                      th(colspan=2) {{ $t('order_print.left_to_pay') }}
                      td.has-text-right  {{ decimal(prop(object, "leftToPayValue")) }}

              app-panel( v-if="priceType !== 'sales'" :title="$t('order_print.summary')")
                table.table.is-bordered.has-margin-bottom.is-fullwidth.is-narrow
                  tbody
                    tr(v-if="printType === 'full'")
                      th(colspan=2) Wartość netto
                      td.has-text-right {{ decimal(object.netPurchaseValueBeforeDiscount)}}
                    tr(v-if="printType === 'full'")
                      th(colspan=2) Wartość netto automatyki
                      td.has-text-right {{ decimal(object.netPurchaseAutomationValueBeforeDiscount)}}
                    tr
                      th(colspan=2) {{ $t('order_print.sum_net_value') }}
                      td.has-text-right  {{ decimal(prop(object, "totalNetPurchaseValueBeforeDiscount")) }}
                    tr
                      th {{ $t('order_print.vat') }}
                      td.has-text-right  {{ prop(object, "vatRate", "name") }}
                      td.has-text-right  {{ decimal(prop(object, "vatRateValue")) }}
                    tr
                      th(colspan=2) {{ $t('order_print.gross_value') }}
                      td.has-text-right  {{ decimal(prop(object, "grossPurchaseValueBeforeDiscount")) }}
                    tr
                      th(colspan=2) {{ $t('order_print.gross_value_after_discout') }}
                      td.has-text-right  {{ decimal(prop(object, "totalPurchaseGrossValue")) }}
                    tr(v-if='prop(object, "customPurchaseGrossValue") !== null')
                      th(colspan=2).has-text-danger     {{ $t('order_print.custom_gross_value_after_discout') }}
                      td.has-text-right.has-text-danger {{ decimal(prop(object, "customPurchaseGrossValue")) }}

          br
          br
          .columns
            .column.is-12.small-text
              p 1. {{ $t('order_print.regulation_1') }}
              p 2. {{ $t('order_print.regulation_2') }}
              p 3. {{ $t('order_print.regulation_3') }}
          br
          br
          br
          .columns
            .column.has-text-centered.small-text
              p .........................................................
              p {{ $t('order_print.stamp_and_signature') }}
            .column
            .column.has-text-centered.small-text
              p .........................................................
              p {{ $t('order_print.client_acceptation_1') }}
              p {{ $t('order_print.client_acceptation_2') }}
        .buttons.is-centered
          b-button(@click="printOrder" type="is-primary") {{ $t('print') }}
</template>

<script>
import decimal from "@/helpers/decimal";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import http from "@/http";
import { mapGetters } from "vuex";
import generateProperties from "@/helpers/generateProperties";
import ExternalShutterOrderItems from "./orderTables/external_shutters/ExternalShutterOrderItems.vue";
import ExternalShutterOrderItemsAfterSum from "./orderTables/external_shutters/ExternalShutterOrderItemsAfterSum.vue";
import FabricShadeOrderItems from "./orderTables/fabric_shades/FabricShadeOrderItems.vue";
import AutomationOrderItems from "./orderTables/automations/AutomationOrderItems.vue";
import FabricShadeDayNightOrderItems from "./orderTables/fabric_shade_day_nights/FabricShadeDayNightOrderItems.vue";
import PleatOrderItems from "./orderTables/pleats/PleatOrderItems.vue";
import DetailOrderItems from "./orderTables/details/DetailOrderItems.vue";

export default {
  components: {
    DetailOrderItems,
    FabricShadeDayNightOrderItems,
    FabricShadeOrderItems,
    PleatOrderItems,
    ExternalShutterOrderItemsAfterSum,
    ExternalShutterOrderItems,
    AutomationOrderItems
  },
  props: {
    active: {type: Boolean, default: false, required: true},
    orderId: {type: Number, default: null},
    order: {type: Object, default: null},
    printType: {type: String, default: "full"},
    priceType: {default: null, required: true}
  },

  data() {
    return {
      object: null,
      loading: false,
      isActive: this.active,
      tdStyle: {
        padding: "3px"
      }
    };
  },
  methods: {
    getPropertiesForOrderQuery() {
      const props =
          generateProperties(
              [],
              ["id", "category", "valuationDate", "orderDate", "netSalesValue", "netPurchaseValue", "netPurchaseValueBeforeDiscount", "netPurchaseAutomationValueBeforeDiscount", "netPurchaseAutomationValue", "netSalesValue", "netSalesAutomationValue", "installationValue", "fitterTravelCostValue", "totalSalesNetValue", "vatRate", "companyVatRate", "salesGrossValue", "advanceValue", "leftToPayValue", "salesVatRateValue", "netSalesAutomationValue", "totalSalesNetValue", "totalPurchaseGrossValue", "vatRateValue", "totalNetPurchaseValueBeforeDiscount", "grossPurchaseValueBeforeDiscount",
                "paymentMethod", "discountValue", "discountAutomationValue", "netSalesValueAfterDiscount", "netSalesAutomationValueAfterDiscount"
                , "percentDiscount", "percentDiscountAutomation", "status", "customPurchaseGrossValue", "automationOrderItems"]) +
          generateProperties(
              ["customer"],
              ["companyName", "taxId", "firstName", "lastName", "type", "city", "phone", "streetAndNumber", "zipCode", ""],
              true
          ) +
          generateProperties(
              ["company"],
              ["name", "city", "taxId", "phone", "streetAndNumber", "zipCode", "city"],
              true
          ) +
          generateProperties(
              ["subCompany"],
              ["name", "city", "taxId", "phone", "streetAndNumber", "zipCode", "city", "hasInvoicingByProducer", "company"],
              true
          ) +
          generateProperties(
              ["externalShutterOrderItems"],
              ["id", "@id", "quantity", "weight", "netPurchaseValue", "netSalesValue", "shortDescription", "selectedCombination", "externalShutterOrderItemCombinationsCount",
                "externalShutter",
                "externalShutterPriceList",
                "externalShutterArmorExternalShutterArmorColorEntry",
                "temporaryExternalBoxColor",
                "temporaryInternalBoxColor",
                "externalShutterRunnerFabricShadeColorEntry",
                "externalShutterArmorFabricShadeColorEntry",
                "sumSurfacesAllCombinations"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations"],
              ["id", "@id", "quantity", "weight", "netPurchaseValue", "netSalesValue", "hasMosquitoNet", "width", "globalPipe", "runnersHeight", "fullHeight",
                "externalShutterBox", "externalShutterExternalShutterRunnerEntryLeft",
                "externalShutterExternalShutterRunnerEntryRight",
                "externalShutterDrillHoleLeft",
                "externalShutterDrillHoleRight",
                "externalShutterExternalShutterProtectionEntries",
                "externalShutterAdaptation",
                "externalShutterDriveExit",
                "globalDrive"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "globalPipe"],
              ["id", "@id", "symbol"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterBox"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterDrillHoleLeft"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterDrillHoleRight"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterAdaption"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterDriveExit"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterExternalShutterProtectionEntries", "externalShutterProtection"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterExternalShutterRunnerEntryLeft", "externalShutterRunner"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterExternalShutterRunnerEntryRight", "externalShutterRunner"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems"],
              ["id", "@id", "quantity", "netPurchaseValue", "netSalesValue", "width", "fabricWidth", "glassWidth", "height", "glassHeight", "driveSide", "hasTurnableFabric", "shortDescription"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeGroup"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShade"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabric"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricColor"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeGroup"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeGlobalAccessoryEntry", "globalAccessory"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeGlobalBalkEntry",
                "globalBalk"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeGlobalFixingEntry",
                "globalFixing"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeGlobalRunnerEntry",
                "globalRunner"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeFabricShadeAdditionalEntries"],
              ["isEnabled"],
              true
          ) +
          generateProperties(
              ["fabricShadeOrderItems", "fabricShadeFabricShadeAdditionalEntries", "fabricShadeAdditional"],
              ["id", "name", "picture", "thumbnail", "message"],
              true
          ) +
          generateProperties(
              ["fabricShadeOrderItems", "globalDrive"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "globalDriveControl"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeOrderItems", "globalRemoteControl"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems"],
              ["id", "@id", "quantity", "netPurchaseValue", "netSalesValue", "width", "fabricWidth", "glassWidth", "height", "glassHeight", "driveSide", "hasTurnableFabric", "shortDescription"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGroup"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNight"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricDayNight"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricColorDayNight"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGroup"],
              ["id", "@id", "name", "number", "position"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalAccessoryEntry", "globalAccessory"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalBalkEntry",
                "globalBalk"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalFixingEntry",
                "globalFixing"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalRunnerEntry",
                "globalRunner"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightFabricShadeDayNightAdditionalEntries"],
              ["isEnabled"],
              true
          ) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "fabricShadeDayNightFabricShadeDayNightAdditionalEntries", "fabricShadeDayNightAdditional"],
              ["id", "name", "picture", "thumbnail", "message"],
              true
          ) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "globalDrive"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadeDayNightOrderItems", "globalDriveControl"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["fabricShadDayNightOrderItems", "globalRemoteControl"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems"],
              ["id", "@id", "quantity", "netPurchaseValue", "netSalesValue", "width", "height", "glassHeight", "shortDescription"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleat"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatFabric"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatFabricColor"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatGroup"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatFabricBottom"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatFabricColorBottom"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatGroupBottom"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatGlobalAccessoryEntry", "globalAccessory"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatAdditionalString",
                "globalRunner"], ["id", "@id", "name", "symbol"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatPleatFixingTypeEntry",
                "pleatFixingType"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatGlobalRunnerEntry",
                "globalRunner"], ["id", "@id", "name"],
              true) +
          generateProperties(
              ["pleatOrderItems", "pleatPleatAdditionalEntries", "pleatAdditional"],
              ["id", "name"],
              true
          ) +
          generateProperties(
              ["pleatOrderItems", "pleatPleatRemoteControlTypeEntry", "pleatRemoteControlType"],
              ["id", "@id", "name"],
              true) +
          generateProperties(
              ["detailOrderItems"],
              ["id", "@id", "quantity", "netPurchaseValue", "netSalesValue", "shortDescription", "detail", "detailUnit", "pieces", "quantity"],
              true)
      return props;
    },
    countOrderQuantity(order) {
      let quantity = 0;
      const orderItems = this.orderItems(order);
      for (const idx in orderItems) {
        quantity += orderItems[idx].quantity;
      }
      return quantity;
    },
    orderHasAtLestOneDriveControl(order) {
      const orderItems = this.orderItems(order);
      let hasAtLestOneDriveControl = false;
      for (const idx in orderItems) {
        if (orderItems[idx]["globalDriveControl"]) {
          hasAtLestOneDriveControl = true;
          break;
        }
      }
      return hasAtLestOneDriveControl;
    },
    decimal(val) {
      return decimal(val) || null;
    },
    getCustomerField(order, field) {
      const customer = order.customer;
      if (!customer) {
        return null;
      }
      return customer[field] || null;
    },
    getOrderNumber(order) {
      return orderNumberCreator(order.id);
    },
    onClose() {
      this.isActive = false;
      this.$emit("close");
    },
    isSubCompany(object) {
      return object.subCompany !== null;
    },
    getValueFromCompanyType(object, field, extraField = null) {
      if (!object || typeof object !== 'object') {
        return null;
      }

      const getValue = (entity, field, extraField) => {
        if (!entity) return null;
        if (extraField) {
          return entity[extraField] ? entity[extraField][field] || null : null;
        }
        return entity[field] || null;
      };

      let value = getValue(object.company, field, extraField);
      if (value !== null) {
        return value;
      }

      value = getValue(object.subCompany, field, extraField);
      return value;
    },
    getValueFromCompanyInSubCompanyType(object, field) {
      return object.subCompany[field] || null;
    },
    orderItems: function (automation = false) {
      const {category} = this.object;

      switch (category) {
        case "fabric_shade":
          return automation ? this.object.automationOrderItems : this.object.fabricShadeOrderItems;
        case "fabric_shade_day_night":
          return automation ? this.object.automationOrderItems : this.object.fabricShadeDayNightOrderItems;
        case "external_shutter":
          return automation ? this.object.automationOrderItems : this.object.externalShutterOrderItems;
        case "pleat":
          return automation ? this.object.automationOrderItems : this.object.pleatOrderItems;
        case "detail":
          return automation ? this.object.automationOrderItems : this.object.detailOrderItems;
        default:
          return [];
      }
    },
    prop(object, field, extraField = null) {
      if (extraField) {
        return object && object[field] && object[field][extraField];
      }
      return object && object[field];
    },
    positionsTitle(order) {
      if (order.category === "fabric_shade") {
        return this.$t("product_types.fabric_shade");
      }

      if (order.category === "fabric_shade_day_night") {
        return this.$t("product_types.fabric_shade_day_night");
      }

      if (order.category === "external_shutter") {
        return this.$t("product_types.external_shutter");
      }

      if (order.category === "pleat") {
        return this.$t("product_types.pleat");
      }

      if (order.category === "detail") {
        return this.$t("product_types.detail");
      }
    },
    async downloadOrder(orderId) {
      this.loading = true;

      let {data} = await http.get(`/orders/${orderId}?${this.getPropertiesForOrderQuery()}`);
      const companyVatRate = await http.get(data.companyVatRate);
      data.companyVatRate = companyVatRate.data;
      this.object = data;
      this.loading = false;
    },
    printOrder() {
      this.$htmlToPaper("order");
    }
  },
  computed: {
    ...mapGetters("auth", ["fullName"])
  },
  watch: {
    active(val) {
      if (val && parseInt(this.orderId)) {
        this.downloadOrder(this.orderId);
      }
      this.isActive = !!val;
    },
    orderId(val) {
      if (
          parseInt(val) &&
          this.isActive &&
          val !== this.orderId &&
          !this.order
      ) {
        this.downloadOrder(val);
      }
    },
    order(val) {
      if (val) {
        this.object = val;
      }
    }
  }
};
</script>
