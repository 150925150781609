import http from "../../http";
import simpleClone from "../../helpers/simpleClone";
import notify from "@/helpers/notify";
import Vue from "vue";
import t from "@/i18n";
import router from "@/router";
import prepareOrderToSent from "@/helpers/prepareOrderToSent";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import objectToCollection from "@/helpers/objectToCollection";
import store from "../../store";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

let defaultItem = {
  quantity: 1,
  detail: null,
  pieces: 1,
  netPurchaseValue: 0,
  netSalesValue: 0,
  companyInstallationValue: null,
  shortDescription: null,
  detailUnit: null,
  metricUnit: null,
};
let defaultOrder = {
  name: "",
  category: "",
  detailOrderItems: [],
  customer: {
    type: "person",
    companyName: null,
    firstName: null,
    lastName: null,
    zipCode: null,
    city: null,
    streetAndNumber: null,
    phone: null,
    email: null,
    taxId: null
  },
  netSalesValue: 0,
  salesGrossValue: 0,
  totalSalesNetValue: 0,
  leftToPayValue: 0,
  netSalesValueAfterDiscount: 0,
  percentDiscount: 0,
  withInstallation: false,
  installationValue: 0,
  installationValuePerItem: 0,
  fitterTravelCost: null,
  fitterTravelCostValue: 0,
  advanceValue: 0,
  vatRate: null,
  vatRateValue: 0,
  companyVatRate: null,
  salesVatRateValue: 0,
  paymentMethod: null,
  deliveryMethod: null,
  discountValue: 0,
  packageNote: "",
  notes: ""
};
export default {
  namespaced: true,
  state: {
    extraPackageNote: "",
    showForm: true,
    errors: [],
    loading: false,
    item: simpleClone(defaultItem),
    order: simpleClone(defaultOrder),
    editIndex: null,
    gusFetching: false,
    message: null,
    isMessageBorderAnimating: false,
  },
  getters: {
    extraPackageNote: state => state.extraPackageNote,
    orderNumber: state => {
      let number = "";
      if (state.order && state.order.id) {
        for (let i = state.order.id.toString().length; i < 6; i++) {
          number += "0";
        }
        number += state.order.id.toString();
      }
      return number;
    },
    customErrors: state => state.customErrors,
    showForm: state => state.showForm,
    errors: state => state.errors,
    isMessageBorderAnimating: state => state.isMessageBorderAnimating,
    message: state => state.message,
    image: () => (object, imageName, extraField = null) => {
      let picture = null

      if (extraField) {
        const field = object && object[extraField];
        if (field) {
          const picture = field[imageName];
          if (picture) {
            return picture.contentUrl;
          }
          return null;
        }
        return null;
      }

      if (imageName) {
        picture = object && object[imageName];
      } else {
        picture = object
      }
      if (picture) {
        return picture.contentUrl;
      }
      return null;
    },
    isEdit: (state, getters) => !!getters.item.id,
    item: state => state.item,
    editIndex: state => state.editIndex,
    order: state => state.order,
    loading: state => state.loading,
    gusFetching: state => state.gusFetching,

  },
  mutations: {
    setExtraPackageNote(state, payload) {
      state.extraPackageNote = payload;
    },
    toggleShowForm(state) {
      state.showForm = !state.showForm;
    },
    addError(state, payload) {
      state.errors.push({
        propertyPath: payload.field,
        message: payload.message
      });
    },
    deleteError(state, name) {
      const index = state.errors.findIndex(
        error => error.propertyPath === name
      );
      if (index > -1) {
        Vue.delete(state.errors, index);
      }
    },
    setIsMessageBorderAnimating(state, payload) {
      state.isMessageBorderAnimating = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    endLoading(state) {
      state.loading = false;
    },
    startGusFetching(state) {
      state.gusFetching = true;
    },
    endGusFetching(state) {
      state.gusFetching = false;
    },
    setDetailOrderItems(state, items) {
      state.order.detailOrderItems = items;
    },
    setOrder(state, order) {
      state.order = order;
    },
    saveItem(state, addedItem) {
      let index = state.order.detailOrderItems.findIndex(
        item => item.id === addedItem.id
      );
      Vue.set(
        state.order.detailOrderItems,
        index,
        simpleClone(addedItem)
      );

      state.item.shortDescription = null;
      // delete state.item["@id"]
      state.item.id = null
      notify("Dodano pozycję do zamówienia");
    },
    addItem(state, addedItem) {
      state.order.detailOrderItems.push(simpleClone(addedItem));
      state.item.shortDescription = null;

      notify("Dodano pozycję do zamówienia");
    },
    removeItem(state, index) {
      state.order.detailOrderItems.splice(index, 1);
    },
    removeAutomationItem(state, index) {
      state.order.automationOrderItems.splice(index, 1);
    },
    updateCustomer(state, customer) {
      state.order.customer = customer;
    },
    setItem(state, {item}) {
      state.item = simpleClone(item);
    },
    editItem(state, {index, item}) {
      state.editIndex = index;
      state.item = simpleClone(item);
    },
    resetEditIndex(state) {
      state.editIndex = null;
    },
    setCustomerByGusApiData(state, gusData) {
      state.order.customer.companyName = gusData.name;
      state.order.customer.taxId = gusData.nip;
      state.order.customer.zipCode = gusData.zipCode;
      state.order.customer.city = gusData.city;
      state.order.customer.streetAndNumber = `${gusData.street} ${gusData.propertyNumber}`;
      state.order.customer.firstName = null;
      state.order.customer.lastName = null;
      state.order.customer.phone = null;
    },
    resetCustomer(state) {
      if (state.order.customer.id) {
        delete state.order.customer.id;
        delete state.order.customer["@id"];
        delete state.order.customer["@type"];
      }
      state.order.customer.companyName = null;
      state.order.customer.taxId = null;
      state.order.customer.zipCode = null;
      state.order.customer.city = null;
      state.order.customer.streetAndNumber = null;
      state.order.customer.firstName = null;
      state.order.customer.lastName = null;
      state.order.customer.phone = null;
      state.order.customer.email = null;
    },
    setCustomer(state, customer) {
      state.order.customer = customer;
    }
  },
  actions: {
    setExtraPackageNote({commit}, payload) {
      commit("setExtraPackageNote", payload);
    },
    deleteError({commit}, errorName) {
      commit("deleteError", errorName);
    },
    toggleShowForm({commit}) {
      commit("toggleShowForm");
    },
    setIsMessageBorderAnimating({commit}, payload) {
      commit("setIsMessageBorderAnimating", payload);
    },
    setMessage({commit}, payload) {
      commit("setMessage", payload);
    },
    cancelEdit({commit, dispatch}) {
      commit("resetEditIndex");
      commit("resetChangeStatusesForEditItem");
      dispatch("setItemDefaults");
    },

    async saveCustomer({commit, state}, type = "create") {
      commit("startLoading");
      let error = {field: null, message: null};
      let isError = false;
      if (state.order.customer.type === "person") {
        if (!state.order.customer.lastName || state.order.customer.lastName === "") {
          error.field = "lastName";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_last_name_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "lastName");
        }
        if (!state.order.customer.city || state.order.customer.city === "") {
          error.field = "city";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_city_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "city");
        }
        if (isError) {
          return;
        }
      } else if (state.order.customer.type === "company") {
        if (
          !state.order.customer.companyName ||
          state.order.customer.companyName === "" ||
          !state.order.customer.taxId ||
          state.order.customer.taxId === "" ||
          !state.order.customer.city ||
          state.order.customer.city === ""
        ) {
          if (
            !state.order.customer.companyName ||
            state.order.customer.companyName === ""
          ) {
            error.field = "companyName";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!state.order.customer.taxId || state.order.customer.taxId === "") {
            error.field = "taxId";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!state.order.customer.city || state.order.customer.city === "") {
            error.field = "city";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
          commit("endLoading");
          return;
        } else {
          if (state.order.customer.companyName && state.order.customer.companyName !== "") {
            commit("deleteError", "companyName");
          }
          if (state.order.customer.taxId && state.order.customer.taxId !== "") {
            commit("deleteError", "texId");
          }
        }
      }
      if (type === "create" && state.order.customer.id) {
        delete state.order.customer.id;
        delete state.order.customer["@id"];
        delete state.order.customer["@type"];
      }
      let method = type === "create" ? "post" : "put";
      let url = type === "create" ? "/customers" : `/customers/${state.order.customer.id}`;
      await http[method](url, state.order.customer)
        .then(response => {
          const data = response.data;
          notify(t.t("customer_updated"));
          commit("updateCustomer", data);
        })
        .catch(error => {
          const resp = error.response;
          if (resp) {
            if (resp.status === 400) {
              const violations = resp.data.violations;
              violations.forEach(violation => {
                notify(
                  `${t.t("field")} ${t.t(violation.propertyPath)} ${t.t(
                    violation.message
                  )}`,
                  "warning"
                );
              });
            }
          }
        });
      commit("endLoading");
    },
    async removeItem({commit, dispatch, state}, index) {
      commit("removeItem", index);
      commit("resetChangeStatusesForEditItem");
      if (state.editIndex === index) {
        dispatch("setItemDefaults");
      }
      await dispatch("updateOrder");
    },
    async saveItemAndClose({dispatch}) {
      dispatch("saveItem").then(dispatch("valuateOrder"));
    },
    async saveItem({commit, dispatch, getters}, addedItem) {
      if (getters.isEdit) {
        commit("saveItem", addedItem);
        commit("resetEditIndex");
        commit("resetChangeStatusesForEditItem");
        // dispatch("setItemDefaults");
        notify("Zmieniono pozycję zamówienia");
      } else {
        commit("addItem", addedItem);
      }
      await dispatch("updateOrder");
    },
    async clearOrder({state, dispatch, commit}) {
      let order = simpleClone(state.order);
      dispatch("updateClearOrder", {order})
      state.order.detailOrderItems = [];

      state.order.netSalesValue = 0;
      state.order.salesGrossValue = 0;
      state.order.totalSalesNetValue = 0;
      state.order.leftToPayValue = 0;
      state.order.netSalesValueAfterDiscount = 0;
      state.order.percentDiscount = 0;
      state.order.withInstallation = false;
      state.order.installationValue = 0;
      state.order.installationValuePerItem = 0;
      state.order.fitterTravelCost = null;
      state.order.fitterTravelCostValue = 0;
      state.order.advanceValue = 0;
      state.order.discountValue = 0;
      state.order.packageNote = "";
      state.order.notes = "";

      commit("")
      commit("resetCustomer")
      commit("setExtraPackageNote", "");

      state.editIndex = null;
      dispatch("updateOrder");
    },
    updateClearOrder: async function (state, {order}) {
      const orderData = prepareOrderToSent(order);
      orderData.customer = null
      http.put(`/orders/${order.id}/clear`, orderData);
    },
    updateOrder: debounce(async function ({commit, getters, rootState}) {
      // commit("common/startLoading", null, {root: true})
      // commit("startLoading");
      let order = simpleClone(getters.order);
      let customer = simpleClone(getters.order.customer);
      if (!customer.id || !customer["@id"]) {
        order.customer = null;
      }
      order = prepareOrderToSent(order);

      if (!order.number) {
        order.number = order.id;
      }

      if (rootState.company.buyType === "wholesale") {
        order.customer = null;
      }

      let {data} = await http.put(`/orders/${order.id}`, order);

      if (data.percentDiscount !== order.percentDiscount) {
        commit("addError", {
          field: "percentDiscount",
          message: "percent_discount_exceeded"
        });
      } else {
        commit("deleteError", "percentDiscount");
      }

      if (!customer.id || !customer["@id"]) {
        data.customer = customer;
      }
      commit("setOrder", data);
      // commit("endLoading");
      // commit("common/endLoading", null, {root: true})
    }, 1000),
    async placeOrder(
      {state, commit, dispatch, rootState, rootGetters},
      isEditing = false
    ) {
      if (state.order.detailOrderItems.length === 0) {
        notify(t.t("order_cant_be_empty"), "warning");
        return;
      }

      commit("startLoading");
      let order = simpleClone(state.order);
      order = prepareOrderToSent(order);
      if (!order.number) {
        order.number = order.id;
      }

      let error = {field: null, message: null};
      let isError = false;
      if (order.customer.type === "person") {
        if (!order.customer.lastName || order.customer.lastName === "") {
          error.field = "lastName";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_last_name_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "lastName");
        }
        if (!order.customer.city || order.customer.city === "") {
          error.field = "city";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_city_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "city");
        }
        if (isError) {
          return;
        }
      } else if (order.customer.type === "company") {
        if (
          !order.customer.companyName ||
          order.customer.companyName === "" ||
          !order.customer.taxId ||
          order.customer.taxId === "" ||
          !order.customer.city ||
          order.customer.city === ""
        ) {
          if (
            !order.customer.companyName ||
            order.customer.companyName === ""
          ) {
            error.field = "companyName";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.taxId || order.customer.taxId === "") {
            error.field = "taxId";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.city || order.customer.city === "") {
            error.field = "city";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
          commit("endLoading");
          return;
        } else {
          if (order.customer.companyName && order.customer.companyName !== "") {
            commit("deleteError", "companyName");
          }
          if (order.customer.taxId && order.customer.taxId !== "") {
            commit("deleteError", "texId");
          }
        }
      }
      if (rootState.company.buyType === "wholesale") {
        order.customer = null;
      }
      let note = order.packageNote || "";

      if (state.extraPackageNote) {
        if (order.packageNote) note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
        note = state.extraPackageNote + "." + note;
      }

      order.packageNote = note;

      const {data} = await http.put(
        `/orders/${state.order.id}/complete`,
        order
      );
      commit("setExtraPackageNote", "");
      if (data.status === "new") {
        // redirect to order show
      }

      if (isEditing) {
        await router.push({name: "details_create_order"});
      } else {
        // asking api for draft order as on launch and set defaults values for item after placing order
        dispatch("getOrder");
        // dispatch("setItemDefaults");
      }
      commit("endLoading");
      notify(t.t("new_complete_order_added"));

      notify(t.t("new_complete_order_added_thanks", {orderId: orderNumberCreator(order.id)}) + "<br>" +
        t.t("new_complete_order_added_price", {price: rootGetters["common/formatPrice"](order.totalPurchaseGrossValue)}) + "<br>" +
        t.t('new_complete_order_added_more'), "success", "is-top", 7000);
    },
    async valuateOrder(
      {state, commit, dispatch, rootState},
      isEditing = false
    ) {
      if (state.order.detailOrderItems.length === 0) {
        notify(t.t("order_cant_be_empty"), "warning");
        return;
      }

      commit("startLoading");
      let order = simpleClone(state.order);
      order = prepareOrderToSent(order);

      if (!order.number) {
        order.number = order.id;
      }

      let error = {field: null, message: null};
      let isError = false;
      if (order.customer.type === "person") {
        if (!order.customer.lastName || order.customer.lastName === "") {
          error.field = "lastName";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_last_name_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "lastName");
        }
        if (!order.customer.city || order.customer.city === "") {
          error.field = "city";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_city_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "city");
        }
        if (isError) {
          return;
        }
      } else if (order.customer.type === "company") {
        if (
          !order.customer.companyName ||
          order.customer.companyName === "" ||
          !order.customer.taxId ||
          order.customer.taxId === "" ||
          !order.customer.city ||
          order.customer.city === ""
        ) {
          if (
            !order.customer.companyName ||
            order.customer.companyName === ""
          ) {
            error.field = "companyName";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.taxId || order.customer.taxId === "") {
            error.field = "taxId";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.city || order.customer.city === "") {
            error.field = "city";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
          commit("endLoading");
          return;
        } else {
          if (order.customer.companyName && order.customer.companyName !== "") {
            commit("deleteError", "companyName");
          }
          if (order.customer.taxId && order.customer.taxId !== "") {
            commit("deleteError", "texId");
          }
        }
      }

      if (rootState.company.buyType === "wholesale") {
        order.customer = null;
      }

      let note = order.packageNote || "";

      if (state.extraPackageNote) {
        if (order.packageNote) note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
        note = state.extraPackageNote + "." + note;
      }

      order.packageNote = note;

      const {data} = await http.put(
        `/orders/${state.order.id}${isEditing ? "" : "/valuation"}`,
        order
      );
      commit("setExtraPackageNote", "");
      if (data.status === "new") {
        // redirect to order show
      }

      if (isEditing) {
        await router.push({name: "details_create_order"});
      } else {
        // asking api for draft order as on launch and set defaults values for item after placing order
        dispatch("getOrder");
        dispatch("setItemDefaults");
      }

      commit("endLoading");
      notify(isEditing ? t.t("valuation_saved") : t.t("new_valuation_added"));
    },
    async getOrderForCopy({commit, dispatch}, id) {
      try {
        const order = (await http.get(`/orders/${id}`)).data;

        //wait for all recalculate, so use it as promise
        await Promise.all(
          order.detailOrderItems.map(item => {
            delete item.id;
          }))
        commit("setDetailOrderItems", order.detailOrderItems);
        commit("setCustomer", order.customer);
        dispatch("updateOrder");


      } catch (e) {
        notify(t.t("order_copy_error", {id}), "error", "is-top", 7000);
      }
    },

    async getOrder({commit, rootGetters}, id = null) {
      //check if id is set, if yes, its valuation edit mode
      let order = null;
      if (id !== null && !isNaN(id)) {
        let response = await http.get(`/orders/${id}`);
        order = response.data;
      } else {
        // find drafts
        let {data} = await http.get(
          `/orders?${store.getters["auth/companyTypeForUrlProp"]}.id=${store.getters["auth/companyId"]}&status=draft&category=detail&properties[]=id`
        );
        // get first draft by id as order
        if (data["hydra:member"].length > 0) {
          let first = data["hydra:member"][0];
          let response = await http.get(`/orders/${first.id}`);
          order = response.data;
        } else {
          let params = {
            category: "detail",
            customer: null
          };
          // create new order
          let response = await http.post("/orders", params);
          order = response.data;
        }
        if (order.customer === null) {
          order.customer = {
            type: "person",
            companyName: null,
            firstName: null,
            lastName: null,
            zipCode: null,
            city: null,
            streetAndNumber: null,
            phone: null,
            email: null,
            taxId: null
          };
        }
        if (!order.name) {
          order.name = `Wycena numer ${orderNumberCreator(order.id)}`;
        }
        if (
          !order.companyVatRate &&
          objectToCollection(rootGetters["company/companyVatRates"]).length > 0
        ) {
          let first = null;
          for (const rate in rootGetters["company/companyVatRates"]) {
            if (first === null) {
              first = rate;
            }
            if (
              rootGetters["company/companyVatRates"][rate].name.includes(
                "23"
              ) ||
              rootGetters["company/companyVatRates"][rate].value
                .toString()
                .includes("23")
            ) {
              order.companyVatRate =
                rootGetters["company/companyVatRates"][rate]["@id"];
              break;
            }
          }
          if (!order.companyVatRate) {
            order.companyVatRate =
              rootGetters["company/companyVatRates"][first]["@id"];
          }
        }

        if (
          rootGetters["company/defaultDeliveryMethod"] &&
          !order.deliveryMethod &&
          objectToCollection(rootGetters["company/deliveryMethods"]).length > 0
        ) {
          let first = null;
          for (const delivery in rootGetters["company/deliveryMethods"]) {
            if (first === null) {
              first = delivery;
            }
            if (
              rootGetters["company/deliveryMethods"][delivery]["@id"] ===
              rootGetters["company/defaultDeliveryMethod"]
            ) {
              order.deliveryMethod =
                rootGetters["company/deliveryMethods"][delivery];
              break;
            }
          }
          if (!order.deliveryMethod) {
            order.deliveryMethod =
              rootGetters["company/deliveryMethods"][first];
          }
        }

        if (
          !order.paymentMethod &&
          objectToCollection(rootGetters["company/paymentMethods"]).length > 0
        ) {
          for (const cost in rootGetters["company/paymentMethods"]) {
            order.paymentMethod = rootGetters["company/paymentMethods"][cost];
            break;
          }
        }
      }
      commit("setOrder", order);
    },
    async fetchGus({state, commit}) {
      const nip = state.order.customer.taxId
        .replace(/^\D+/g, "")
        .replaceAll("-", "");
      commit("startGusFetching");
      try {
        let {data} = await http.get(`/gus/nip/${nip}`);
        commit("setCustomerByGusApiData", data);
      } catch (e) {
        commit("endGusFetching");
        window.alert(t.t("gus_problem"));
      }
      commit("endGusFetching");
    },
    resetCustomer({commit}) {
      commit("resetCustomer");
    }
  }
};
