import http from "../../http";
import simpleClone from "../../helpers/simpleClone";
import notify from "@/helpers/notify";
import Vue from "vue";
import t from "@/i18n";
import router from "@/router";
import prepareOrderToSent from "@/helpers/prepareOrderToSent";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import objectToCollection from "@/helpers/objectToCollection";
import generateProperties from "@/helpers/generateProperties";
import convertMilimetersToMeter from "../../helpers/convertMilimetersToMeter";
import convertGramsToKilograms from "../../helpers/convertGramsToKilograms";
import localStorageFuncs from "../../helpers/localStorageHelper";
import prepareItemToSent from "../../helpers/prepareItemToSent";
import store from "../../store";


function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

let defaultCombination = {
  hasMosquitoNet: false,
  globalPipe: null,
  globalDriveType: null,
  globalDriveSeries: null,
  externalShutterDriveExit: null,
  globalDrive: null,
  globalDriveControl: null,
  externalShutterExternalShutterRunnerEntryLeft: null,
  externalShutterExternalShutterRunnerEntryRight: null,
  externalShutterDrillHoleLeft: null,
  externalShutterDrillHoleRight: null,
  externalShutterExternalShutterProtectionEntries: [],
  externalShutterBox: null,
  externalShutterAdaptation: null,
  width: 0,
  runnersHeight: 0,
  fullHeight: 0
};

let defaultItem = {
  externalShutter: null,
  // externalShutterBoxInternalFabricShadeColorEntry: null,
  // externalShutterBoxExternalFabricShadeColorEntry: null,
  externalShutterPriceList: null,
  externalShutterArmorExternalShutterArmorColorEntry: null,
  externalShutterArmorFabricShadeColorEntry: null,
  externalShutterOrderItemCombinationsCount: 1,
  externalShutterOrderItemCombinations: [simpleClone(defaultCombination)],
  temporaryExternalBoxColor: null,
  temporaryInternalBoxColor: null,
  globalRemoteControl: null,
  globalRemoteControlQuantity: 0,
  selectedCombination: "single",
  quantity: 1,
  fabricShadeColor: null,
  externalShutterRunnerFabricShadeColorEntry: null
};

let defaultOrder = {
  name: "",
  category: "external_shutter",
  externalShutterOrderItems: [],
  automationOrderItems: [],
  customer: {
    type: "person",
    companyName: null,
    firstName: null,
    lastName: null,
    zipCode: null,
    city: null,
    streetAndNumber: null,
    phone: null,
    email: null,
    taxId: null
  },
  weigth: null,
  sumSurfacesAllCombinations: null,
  netSalesValue: 0,
  netSalesAutomationValue: 0,
  netPurchaseAutomationValue: 0,
  salesGrossValue: 0,
  totalSalesNetValue: 0,
  leftToPayValue: 0,
  netSalesValueAfterDiscount: 0,
  netSalesAutomationValueAfterDiscount: 0,
  percentDiscount: 0,
  percentDiscountAutomation: 0,
  withInstallation: false,
  installationValue: 0,
  installationValuePerItem: 0,
  fitterTravelCost: null,
  fitterTravelCostValue: 0,
  advanceValue: 0,
  vatRate: null,
  vatRateValue: 0,
  companyVatRate: null,
  salesVatRateValue: 0,
  paymentMethod: null,
  deliveryMethod: null,
  discountValue: 0,
  packageNote: "",
  notes: ""
};
export default {
  namespaced: true,
  state: {
    extraPackageNote: "",
    errors: [],
    loading: false,
    item: simpleClone(defaultItem),
    order: simpleClone(defaultOrder),
    editIndex: null,
    combinationImage: '/external_shutter_combinations/K_single.jpg', // default single combination image
    gusFetching: false,
    message: null,
    isMessageBorderAnimating: false,
    showForm: true,
    externalShutters: []
  },
  getters: {
    externalShutters: state => state.externalShutters,
    externalShutterOrderItemCombinations: state => state.item.externalShutterOrderItemCombinations,
    itemExternalShutterFullWidth: state => {
      const externalShutterOrderItemCombinations = state.item.externalShutterOrderItemCombinations
      if (!externalShutterOrderItemCombinations) {
        return 0;
      }

      return externalShutterOrderItemCombinations.map(combination => combination.width).reduce((prev, next) => prev + next)
    },

    itemExternalShutterMinWidth: state => {
      const externalShutter = state.item.externalShutter
      if (!externalShutter) {
        return 0;
      }

      return externalShutter.minWidth
    },
    itemExternalShutterFullHeight: (state) => {
      const externalShutterOrderItemCombinations = state.item.externalShutterOrderItemCombinations
      if (!externalShutterOrderItemCombinations) {
        return 0;
      }

      return externalShutterOrderItemCombinations.map(combination => combination.fullHeight).reduce((prev, next) => Math.max(prev, next))
    },

    /**
     * Get filtered ExternalShutterAdaptations
     * @param state
     * @returns {(function(*): ([]|*))|*}
     */
    itemExternalShutterAdaptations: (state, getters) => id => {
      const {externalShutterBox} = getters.externalShutterOrderItemCombinations[id];

      if (!externalShutterBox || !externalShutterBox.externalShutterAdaptations) {
        return [];
      }

      return externalShutterBox.externalShutterAdaptations.filter(adaptation => {

        if (adaptation.hasMosquitoNet) {
          return true
        }

        if (!adaptation.hasMosquitoNet && adaptation.hasMosquitoNet !== getters.itemNeedMosquitoNet()) {
          return false;
        }

        return true;
      });
    },


    itemExternalShutterBoxes: (state, getters) => (id, typeHeight = 'runners') => {
      const externalShutter = state.item.externalShutter;

      id = getters.itemExternalShutterCombinationMaxRunnerHeight

      if (!externalShutter || !state.item.externalShutterPriceList ||
        !state.item.externalShutterPriceList.externalShutterArmor ||
        !state.item.externalShutterOrderItemCombinations[id] ||
        !state.item.externalShutterOrderItemCombinations[id].globalPipe) {
        return [];
      }

      if (!state.item.temporaryExternalBoxColor || !state.item.temporaryInternalBoxColor) {
        return [];
      }

      return externalShutter.externalShutterBoxes.filter(box => {
        // filtr po kolorze skrzynki zewnatrz
        let found = box.externalShutterBoxExternalFabricShadeColorEntries.findIndex(entry => {
          return (entry.fabricShadeColor['@id'] || entry.fabricShadeColor) === (state.item.temporaryExternalBoxColor.fabricShadeColor['@id'] || state.item.temporaryExternalBoxColor.fabricShadeColor)
        }) > -1;

        // filtr po kolorze skrzynki wewnatrz
        if (!found) {
          found = box.externalShutterBoxInternalFabricShadeColorEntries.findIndex(entry => {
            return (entry.fabricShadeColor['@id'] || entry.fabricShadeColor) === (state.item.temporaryInternalBoxColor.fabricShadeColor['@id'] || state.item.temporaryInternalBoxColor.fabricShadeColor)
          }) > -1;
        }

        // filtr po wybraniu moskitiery

        if (box.hasMosquitoNet !== getters.itemNeedMosquitoNet()) return false


        //filtr helper do wyeksponowania nawoju z listy
        let index = box.externalShutterPipeExternalShutterArmorEntries.findIndex(entry => {
          return ((entry['globalPipe']['@id'] == state.item.externalShutterOrderItemCombinations[id].globalPipe['@id']) &&
            (entry['externalShutterArmor']['@id'] == state.item.externalShutterPriceList.externalShutterArmor['@id']))
        });
        let fullHeight = 0
        // filtr po maksymalnej długości nawoju
        if (typeHeight === 'runners') fullHeight = state.item.externalShutterOrderItemCombinations[id].runnersHeight + box.height
        else if (typeHeight === 'full') fullHeight = getters.externalShutterOrderItemCombinations[id].fullHeight

        if (!box.externalShutterPipeExternalShutterArmorEntries[index]) return false
        if ((box.externalShutterPipeExternalShutterArmorEntries[index].value / 100) > fullHeight) return found //    dzielone przez 100 z powodu zapisu w bazie


        // found = box.hasMosquitoNet === state.item.externalShutterOrderItemCombinations[id].hasMosquitoNet
        return false
      }) || [];

    },


    //pobierz id kombinacji o największym wpisanym pancerzu
    itemExternalShutterCombinationMaxRunnerHeight: (state) => {

      let combinations = simpleClone(state.item.externalShutterOrderItemCombinations)

      const maxRunnersHeight = combinations.reduce((p, c) => p.runnersHeight > c.runnersHeight ? p : c);

      return combinations.indexOf(maxRunnersHeight)

    },

    // pobierz minimalny dopasowaną ExternalShutterBox dla danej długości i nawoji
    itemExternalShutterBoxToAutocomplete: (state, getters) => (id, typeHeight = 'runners') => {

      id = getters.itemExternalShutterCombinationMaxRunnerHeight

      if (getters.itemExternalShutterBoxes(id).length < 1 || !state.item.externalShutterPriceList.externalShutterArmor || !state.item.externalShutterOrderItemCombinations[id].globalPipe) return null

      let minimalBox = null;
      let minimalExternalShutterPipeExternalShutterArmorEntries = Number.MAX_SAFE_INTEGER;

      getters.itemExternalShutterBoxes(id, typeHeight = 'runners').some(box => {

        let index = box.externalShutterPipeExternalShutterArmorEntries.findIndex(entry => {
          return ((entry['globalPipe']["@id"] === state.item.externalShutterOrderItemCombinations[id].globalPipe['@id']) &&
            (entry['externalShutterArmor']["@id"] === state.item.externalShutterPriceList.externalShutterArmor['@id']))
        });

        //sprawdzenie czy nie ma zapamiętanej większej skrzynki i czy istneije wogole
        if (localStorageFuncs.localStorageGet("externalShutterBox") && localStorageFuncs.localStorageGet("externalShutterBox")['@id'] === box['@id']) {
          minimalBox = box;
          return true;
        } else {
          let fullHeight = getters.externalShutterOrderItemCombinations[id].fullHeight
          if (typeHeight === 'runners') fullHeight = state.item.externalShutterOrderItemCombinations[id].runnersHeight + box.height
          else if (typeHeight === 'full') fullHeight = getters.externalShutterOrderItemCombinations[id].fullHeight

          if ((box.externalShutterPipeExternalShutterArmorEntries[index].value / 100) > fullHeight) {
            if (box.externalShutterPipeExternalShutterArmorEntries[index].value > 0 &&
              box.externalShutterPipeExternalShutterArmorEntries[index].value / 100 < minimalExternalShutterPipeExternalShutterArmorEntries
              && box.hasMosquitoNet === getters.externalShutterOrderItemCombinations[id].hasMosquitoNet) {
              minimalExternalShutterPipeExternalShutterArmorEntries = box.externalShutterPipeExternalShutterArmorEntries[index].value / 100;
              minimalBox = box;
            }
          }
        }
      })

      if (!minimalBox) return null;

      return minimalBox;

    },
    itemHasMosquitoNet: (state) => () => {

      let checkExternalShutterHasMosquitoSetting = false

      if (state.item.externalShutter && state.item.externalShutter.externalShutterBoxes.length < 1) {
        return false;
      }

      if (state.item.externalShutter && state.item.externalShutter.externalShutterBoxes.length > 0) {
        state.item.externalShutter.externalShutterBoxes.forEach((box) => {
          if (box.hasMosquitoNet) checkExternalShutterHasMosquitoSetting = true
        })
      }
      return checkExternalShutterHasMosquitoSetting

    },
    itemNeedMosquitoNet: (state, getters) => () => {

      let checkCombinationsHasMosquitoSetting = false

      if (getters.externalShutterOrderItemCombinations.length > 0) {
        getters.externalShutterOrderItemCombinations.forEach((comb) => {
          if (comb.hasMosquitoNet) {
            checkCombinationsHasMosquitoSetting = true;
          }
        })
      }
      return checkCombinationsHasMosquitoSetting

    },
    itemExternalShutterPriceLists: state => {
      if (!state.item.externalShutter) {
        return [];
      }
      if (!state.item.externalShutter.externalShutterPriceLists) {
        return [];
      }
      return state.item.externalShutter.externalShutterPriceLists;
    },
    itemExternalShutterArmorMaxWidth: state => {
      const externalShutterPriceList = state.item.externalShutterPriceList
      if (!externalShutterPriceList) {
        return 0;
      }
      if (!externalShutterPriceList.externalShutterArmor) {
        return 0;
      }
      return externalShutterPriceList.externalShutterArmor.maxWidth;
    },
    itemExternalShutterArmorExternalShutterArmorColorEntries: state => {
      if (!state.item.externalShutterPriceList) {
        return [];
      }

      if (!state.item.externalShutterPriceList.externalShutterArmor) {
        return [];
      }


      if (state.item.externalShutterPriceList.externalShutterArmor.externalShutterArmorExternalShutterArmorColorEntries.length < 1) {
        return [];
      }

      return state.item.externalShutterPriceList.externalShutterArmor.externalShutterArmorExternalShutterArmorColorEntries;
    },
    itemExternalShutterArmorFabricShadeColorEntries: state => {
      if (!state.item.externalShutterPriceList) {
        return [];
      }

      if (state.item.externalShutterPriceList.externalShutterArmor.externalShutterArmorFabricShadeColorEntries.length < 1) {
        return [];
      }


      return state.item.externalShutterPriceList.externalShutterArmor.externalShutterArmorFabricShadeColorEntries;
    },
    itemExternalShutterExternalShutterRunnerEntries: (state) => (id) => {
      if (!state.item.externalShutter) {
        return [];
      }

      if (state.item.externalShutter.externalShutterExternalShutterRunnerEntries && state.item.externalShutter.externalShutterExternalShutterRunnerEntries.length < 1) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id]) {
        return [];
      }

      if (state.item.externalShutterOrderItemCombinations[id].hasMosquitoNet) {
        return state.item.externalShutter.externalShutterExternalShutterRunnerEntries.filter(entry => {
            return entry.externalShutterRunner['isRunnerUnderMosquitoNet'] === state.item.externalShutterOrderItemCombinations[id].hasMosquitoNet;
          }
        );
      } else {
        return state.item.externalShutter.externalShutterExternalShutterRunnerEntries
      }
    },
    itemExternalShutterRunnerDrillHolesLeft: (state) => (id) => {
      if (!state.item.externalShutterOrderItemCombinations[id]) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterRunnerEntryLeft) {
        return [];
      }
      return state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterRunnerEntryLeft.externalShutterRunner.externalShutterDrillHoles || [];
    },
    itemExternalShutterRunnerDrillHolesRight: (state) => (id) => {
      if (!state.item.externalShutterOrderItemCombinations[id]) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterRunnerEntryRight) {
        return [];
      }
      return state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterRunnerEntryRight.externalShutterRunner.externalShutterDrillHoles || [];
    },
    itemGlobalPipes: state => (id) => {
      if (!state.item.externalShutter || !state.item.externalShutter.globalPipes) {
        return [];
      }

      return state.item.externalShutter.globalPipes.filter(
        pipe => pipe.externalShutterMaxWidth >= state.item.externalShutterOrderItemCombinations[id].width
      )

    },

    globalDriveType: (state) => (id) => {
      if (!state.item.externalShutterOrderItemCombinations[id]) {
        return null;
      }

      if (!state.item.externalShutterOrderItemCombinations[id].globalDriveType) {
        return null;
      }

      return state.item.externalShutterOrderItemCombinations[id].globalDriveType
    },
    globalDriveSeries: (state) => (id) => {
      if (!state.item.externalShutterOrderItemCombinations[id]) {
        return null;
      }

      if (!state.item.externalShutterOrderItemCombinations[id].globalDriveSeries) {
        return null;
      }
      return state.item.externalShutterOrderItemCombinations[id].globalDriveSeries()
    },
    /**
     * Return filtered driveTypes
     * Filtrowane po szerokości oraz dopuszczalnej wadze pancerza
     * Filter by (item.externalShutterOrderItemCombinations[id].width, armorWeight)
     * @param state, id
     * @returns []
     */
    itemDriveTypes: (state) => (id) => {
      if (!state.item || !state.item.externalShutterOrderItemCombinations[id]) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id].width || !state.item.externalShutterOrderItemCombinations[id].fullHeight || !state.item.externalShutterOrderItemCombinations[id].globalPipe || !state.item.externalShutterOrderItemCombinations[id].globalPipe.globalDrives || !state.item.externalShutterPriceList) {
        return [];
      }

      const combinationWidth = state.item.externalShutterOrderItemCombinations[id].width;
      const fullHeight = state.item.externalShutterOrderItemCombinations[id].fullHeight;
      const weightArmorPerOneMeter = state.item.externalShutterPriceList.externalShutterArmor.weight;
      const globalDrives = state.item.externalShutterOrderItemCombinations[id].globalPipe.globalDrives || [];
      const armorWeight = (convertMilimetersToMeter(combinationWidth) * convertMilimetersToMeter(fullHeight)) * parseInt(convertGramsToKilograms(weightArmorPerOneMeter)); // mm^2 to m^2

      if (!globalDrives || globalDrives.length === 0) {
        return [];
      }

      let distinctDrivesIndexes = [];
      let drives = [];
      globalDrives.forEach(drive => {
        if (
          !distinctDrivesIndexes.includes(drive.id) &&
          combinationWidth >= drive.minWidth &&
          combinationWidth <= drive.maxWidth &&
          armorWeight <= (drive.liftingCapacity / 100) // why div 100? example 10.00kg is 1000 in database
        ) {
          drives.push(drive);
          distinctDrivesIndexes.push(drive.id);
        }
      });

      let distinctIndexes = [];
      let filtered = [];

      drives.forEach(drive => {
        const type = drive.globalDriveType;
        if (type && !distinctIndexes.includes(type.id)) {
          distinctIndexes.push(type.id);
          filtered.push(type);
        }
      });

      return filtered;
    },
    itemDriveSeries: (state) => (id) => {
      if (!state.item || !state.item.externalShutterOrderItemCombinations[id] || !state.item.externalShutterOrderItemCombinations[id].globalDriveType) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id].width || !state.item.externalShutterOrderItemCombinations[id].fullHeight || !state.item.externalShutterOrderItemCombinations[id].globalPipe || !state.item.externalShutterOrderItemCombinations[id].globalPipe.globalDrives) {
        return [];
      }

      const globalDriveType = state.item.externalShutterOrderItemCombinations[id].globalDriveType;
      const combinationWidth = state.item.externalShutterOrderItemCombinations[id].width;
      const fullHeight = state.item.externalShutterOrderItemCombinations[id].fullHeight;
      const weightArmorPerOneMeter = state.item.externalShutterPriceList.externalShutterArmor.weight;
      const globalDrives = state.item.externalShutterOrderItemCombinations[id].globalPipe.globalDrives || [];
      const armorWeight = (convertMilimetersToMeter(combinationWidth) * convertMilimetersToMeter(fullHeight)) * parseInt(convertGramsToKilograms(weightArmorPerOneMeter)); // mm^2 to m^2

      if (!globalDrives || globalDrives.length === 0) {
        return [];
      }

      let distinctSeriesIndexes = [];
      let series = [];

      globalDrives.forEach(drive => {
        const seriesObj = drive.globalDriveSeries;
        const type = drive.globalDriveType;
        if (
          type &&
          type["@id"] === globalDriveType["@id"] &&
          seriesObj &&
          !distinctSeriesIndexes.includes(seriesObj.id) &&
          combinationWidth >= drive.minWidth &&
          combinationWidth <= drive.maxWidth &&
          armorWeight <= (drive.liftingCapacity / 100) // why div 100? example 10.00kg is 1000 in database
        ) {
          series.push(seriesObj);
          distinctSeriesIndexes.push(seriesObj.id);
        }
      });
      return series;
    },
    itemGlobalDrives: (state) => (id) => {
      if (
        !state.item.externalShutterOrderItemCombinations[id].globalDriveType ||
        !state.item.externalShutterOrderItemCombinations[id].globalDriveSeries ||
        !state.item ||
        //!state.item.fabricShade ||
        !state.item.externalShutterOrderItemCombinations[id].width
      ) {
        return [];
      }

      const combinationWidth = state.item.externalShutterOrderItemCombinations[id].width;
      const fullHeight = state.item.externalShutterOrderItemCombinations[id].fullHeight;
      const weightArmorPerOneMeter = state.item.externalShutterPriceList.externalShutterArmor.weight;
      const globalDrives = state.item.externalShutterOrderItemCombinations[id].globalPipe.globalDrives || [];
      const armorWeight = (convertMilimetersToMeter(combinationWidth) * convertMilimetersToMeter(fullHeight)) * parseInt(convertGramsToKilograms(weightArmorPerOneMeter)); // mm^2 to m^2

      let distinctDrivesIndexes = [];
      let drives = [];

      globalDrives.forEach(drive => {
        const series = drive.globalDriveSeries;
        const type = drive.globalDriveType;
        if (
          series &&
          type &&
          series["@id"] === state.item.externalShutterOrderItemCombinations[id].globalDriveSeries["@id"] &&
          type["@id"] === state.item.externalShutterOrderItemCombinations[id].globalDriveType["@id"] &&
          !distinctDrivesIndexes.includes(drive.id) &&
          combinationWidth >= drive.minWidth &&
          combinationWidth <= drive.maxWidth &&
          armorWeight <= (drive.liftingCapacity / 100) // why div 100? example 10.00kg is 1000 in database
        ) {
          drives.push(drive);
          distinctDrivesIndexes.push(drive.id);
        }
      });

      return drives;
    },

    itemGlobalDriveControls: (state) => () => {
      let globalDriveControlsInAllCombinations = [];
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          if (it.globalDrive) {
            globalDriveControlsInAllCombinations = [...globalDriveControlsInAllCombinations, ...it.globalDrive["globalDriveControls"]]
          }
        })
      }
      return globalDriveControlsInAllCombinations;
    },

    itemExternalShutterDriveExits: (state) => () => {

      if (!state.item.externalShutter || !state.item.externalShutter.externalShutterDriveExits) return []

      return state.item.externalShutter.externalShutterDriveExits

    },

    /**
     * Check common global remote controls from all elements externalShutterOrderItemCombinations
     * @param state
     * @returns {*[]}
     */
    itemGlobalRemoteControls: (state) => {
      state.item.globalRemoteControl = null;

      if (!state.item.externalShutterOrderItemCombinations) {
        return []
      }
      const externalShutterOrderItemCombinations = state.item.externalShutterOrderItemCombinations
      let checkToCommonArraysRemotes = [];

      externalShutterOrderItemCombinations.forEach(comb => {
          if (comb.globalDriveControl && comb.globalDriveControl.globalRemoteControls) {
            for (let i = 0; i < comb.globalDriveControl.globalRemoteControls.length; i++) {
              checkToCommonArraysRemotes.push(comb.globalDriveControl.globalRemoteControls[i]);
            }
          }
        }
      )

      if (externalShutterOrderItemCombinations.length == 1) return checkToCommonArraysRemotes // when externalShutterOrderItemCombinationsCount == 1

      if (checkToCommonArraysRemotes.length == 0) return []

      let frequencesCommonRemotes = checkToCommonArraysRemotes.reduce((acc, o) => (acc[o.id] = (acc[o.id] || 0) + 1, acc), {}); // check and calculate frequencies element in array

      let availableCommonRemotes = [];
      let externalShutterOrderItemCombinationsCount = externalShutterOrderItemCombinations.length

      Object.keys(frequencesCommonRemotes).forEach(i => {
        let idx = -1;
        if (frequencesCommonRemotes[i] == externalShutterOrderItemCombinationsCount) {
          idx = checkToCommonArraysRemotes.findIndex(x => x.id == i);
          if (idx != -1) availableCommonRemotes.push(checkToCommonArraysRemotes[idx])
        }
      })

      return availableCommonRemotes;
    },
    itemExternalShutterExternalShutterProtectionsEntries: (state) => (id, removeCurrentElementFromArray = false) => {
      if (!state.item.externalShutter) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id]) {
        return [];
      }

      if (!state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterProtectionEntries || state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterProtectionEntries.length < 1) {
        return state.item.externalShutter.externalShutterExternalShutterProtectionEntries || [];
      }

      let conflicts = [];
      let checked = [];
      state.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterProtectionEntries.forEach(entry => {
        checked.push(entry.externalShutterProtection.id);
        entry.externalShutterProtection.externalShutterProtectionConflicts.forEach(conf => {
            if (!conflicts.includes(conf.id)) {
              conflicts.push(conf.id);
            }
          }
        );
      });

      let dataToReturn = [];

      state.item.externalShutter.externalShutterExternalShutterProtectionEntries.forEach(entry => {
          if (checked.includes(entry.externalShutterProtection.id)) {
            if (!removeCurrentElementFromArray) dataToReturn.push(entry);
            return;
          } else if (conflicts.includes(entry.externalShutterProtection.id)) {
            return;
          }
          let conflict = false;
          entry.externalShutterProtection.externalShutterProtectionConflicts.forEach(
            conf => {
              if (checked.includes(conf.id)) {
                conflict = true;
              }
            }
          );
          if (!conflict) {
            dataToReturn.push(entry);
          }
        }
      );

      return dataToReturn;
    },
    itemExternalShutterBoxInternalFabricShadeColorEntries: state => {
      if (!state.item.externalShutter) {
        return [];
      }

      if (!state.item.externalShutter.externalShutterBoxes || state.item.externalShutter.externalShutterBoxes.length < 1) {
        return [];
      }

      let uniqueInternalColorsIds = [];
      let uniques = [];
      const boxes = state.item.externalShutter.externalShutterBoxes;

      boxes.forEach(box => {
        box.externalShutterBoxInternalFabricShadeColorEntries.forEach(entry => {
          if (!uniqueInternalColorsIds.includes(entry.fabricShadeColor.id)) {
            uniqueInternalColorsIds.push(entry.fabricShadeColor.id);
            uniques.push(entry);
          }
        });
      });

      return uniques;
    },
    itemExternalShutterBoxExternalFabricShadeColorEntries: state => {
      if (!state.item.externalShutter) {
        return [];
      }

      if (!state.item.externalShutter.externalShutterBoxes || state.item.externalShutter.externalShutterBoxes.length < 1) {
        return [];
      }

      let uniqueInternalColorsIds = [];
      let uniques = [];
      const boxes = state.item.externalShutter.externalShutterBoxes;

      boxes.forEach(box => {
        box.externalShutterBoxExternalFabricShadeColorEntries.forEach(entry => {
          if (!uniqueInternalColorsIds.includes(entry.fabricShadeColor.id)) {
            uniqueInternalColorsIds.push(entry.fabricShadeColor.id);
            uniques.push(entry);
          }
        });
      });

      return uniques;
    },
    /**
     * Z ta funkcja jest taki problem, ze klient chce kolor prowadnic dla calosci, ale w kazdym elemencie kombinacji pozwala na wybor roznych prowadnic
     * @param state
     */
    itemExternalShutterRunnerFabricShadeColorEntries: state => {
      const externalShutterOrderItemCombinations = state.item.externalShutterOrderItemCombinations;
      let allCombinationsHasRunners = true;
      externalShutterOrderItemCombinations.every(combination => {
        if (!combination.externalShutterExternalShutterRunnerEntryLeft) {
          allCombinationsHasRunners = false;
          return false;
        }

        if (!combination.externalShutterExternalShutterRunnerEntryRight) {
          allCombinationsHasRunners = false;
          return false;
        }
        return true;
      });

      if (!allCombinationsHasRunners) {
        return [];
      }
      let allColors = [];
      externalShutterOrderItemCombinations.forEach(combination => {
        const left = combination.externalShutterExternalShutterRunnerEntryLeft.externalShutterRunner;
        const right = combination.externalShutterExternalShutterRunnerEntryRight.externalShutterRunner;
        let leftColors = [];
        let rightColors = [];
        left.externalShutterRunnerFabricShadeColorEntries.forEach(entry => {
          leftColors.push(entry);
        });

        right.externalShutterRunnerFabricShadeColorEntries.forEach(entry => {
          rightColors.push(entry);
        });

        leftColors.forEach(left => {
          if (rightColors.findIndex(right => right.fabricShadeColor['@id'] === left.fabricShadeColor['@id']) > -1) {
            allColors.push(left);
          }
        });

      });

      allColors = allColors.filter((colors, index) => {
        return allColors.findIndex((c) => c.fabricShadeColor['@id'] === colors.fabricShadeColor['@id']) === index;
      });

      return allColors;

    },
    isSimpleForm: state => state.item.externalShutterOrderItemCombinationsCount === 1,
    showForm: state => state.showForm,
    extraPackageNote: state => state.extraPackageNote,
    orderNumber: state => {
      let number = "";
      if (state.order && state.order.id) {
        for (let i = state.order.id.toString().length; i < 6; i++) {
          number += "0";
        }
        number += state.order.id.toString();
      }
      return number;
    },
    errors: state => state.errors,
    message: state => state.message,
    isEdit: (state, getters) => !!getters.item.id,
    isMessageBorderAnimating: state => state.isMessageBorderAnimating,
    item: state => state.item,
    editIndex: state => state.editIndex,
    combinationImage: state => state.combinationImage,
    order: state => state.order,
    orderAfterSum: state => {
      const orderItems = simpleClone(state.order.externalShutterOrderItems)

      return orderItems.filter((item, index, selfArray) =>
          index === selfArray.findIndex((p) => (
            p.externalShutter['@id'] === item.externalShutter['@id'] &&
            p.externalShutterArmorFabricShadeColorEntry['@id'] === item.externalShutterArmorFabricShadeColorEntry['@id'] &&
            p.temporaryExternalBoxColor['@id'] === item.temporaryExternalBoxColor['@id'] &&
            p.temporaryInternalBoxColor['@id'] === item.temporaryInternalBoxColor['@id'] &&
            p.externalShutterArmorExternalShutterArmorColorEntry['@id'] === item.externalShutterArmorExternalShutterArmorColorEntry['@id'] &&
            p.externalShutterPriceList["externalShutterArmor"]['@id'] === item.externalShutterPriceList["externalShutterArmor"]['@id'] &&
            p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['@id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['@id']

          ))
      );
    },
    checkVirtualVersionNumberAfterSum: (state, getters) => function (item = getters.item) {
      const orderAfterSum = simpleClone(getters.orderAfterSum)
      return orderAfterSum.findIndex((p) => (
        p.externalShutter['@id'] === item.externalShutter['@id'] &&
        p.externalShutterArmorFabricShadeColorEntry['@id'] === item.externalShutterArmorFabricShadeColorEntry['@id'] &&
        p.temporaryExternalBoxColor['@id'] === item.temporaryExternalBoxColor['@id'] &&
        p.temporaryInternalBoxColor['@id'] === item.temporaryInternalBoxColor['@id'] &&
        p.externalShutterArmorExternalShutterArmorColorEntry['@id'] === item.externalShutterArmorExternalShutterArmorColorEntry['@id'] &&
        p.externalShutterPriceList["externalShutterArmor"]['@id'] === item.externalShutterPriceList["externalShutterArmor"]['@id'] &&
        p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['@id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['@id']
      ))
    },

    loading: state => state.loading,
    gusFetching: state => state.gusFetching,
    image: () => (object, imageName, extraField = null) => {
      if (extraField) {
        const field = object && object[extraField];
        if (field) {
          const picture = field[imageName];
          if (picture) {
            return picture.contentUrl;
          }
          return null;
        }
        return null;
      }
      const picture = object && object[imageName];
      if (picture) {
        return picture.contentUrl;
      }
      return null;
    }
  },
  mutations: {
    increaseCombinationsInItem(state, payload) {
      if (!Number.isInteger(payload)) {
        return;
      }

      let diff = payload - state.item.externalShutterOrderItemCombinations.length

      for (let i = 0; i < diff; i++) {
        state.item.externalShutterOrderItemCombinations.push(simpleClone(defaultCombination));
      }
      state.item.externalShutterOrderItemCombinationsCount = payload;
    },
    decreaseCombinationsInItem(state, payload) {
      if (!Number.isInteger(payload)) {
        return;
      }

      state.item.externalShutterOrderItemCombinations.splice(-1, 1)

      state.item.externalShutterOrderItemCombinationsCount = payload;
    },
    setExternalShutters(state, payload) {
      state.externalShutters = payload;
    },
    setItemExternalShutter(state, externalShutter) {
      state.item.externalShutter = state.item.externalShutter ? state.item.externalShutter : externalShutter;
    },


    setItemCombinationsCount(state, count) {
      state.item.externalShutterOrderItemCombinationsCount = count
      state.item.externalShutterOrderItemCombinations = state.item.externalShutterOrderItemCombinations.slice(0, count)
      if (count === 1) state.item.selectedCombination = "single"
    },
    setItemGlobalPipe(state, globalPipe) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.globalPipe = it.globalPipe ? it.globalPipe : globalPipe;
        })
      }
    },
    setItemExternalShutterBox(state, externalShutterBox) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterBox = it.externalShutterBox ? it.externalShutterBox : externalShutterBox;
        })
      }
    },
    setItemExternalShutterPriceList(state, externalShutterPriceList) {
      state.item.externalShutterPriceList = state.item.externalShutterPriceList ? state.item.externalShutterPriceList : externalShutterPriceList;
    },
    setItemExternalShutterArmorExternalShutterArmorColorEntry(state, externalShutterArmorExternalShutterArmorColorEntry) {
      state.item.externalShutterArmorExternalShutterArmorColorEntry = state.item.externalShutterArmorExternalShutterArmorColorEntry ? state.item.externalShutterArmorExternalShutterArmorColorEntry : externalShutterArmorExternalShutterArmorColorEntry;
    },
    setItemExternalShutterArmorFabricShadeColorEntry(state, externalShutterArmorFabricShadeColorEntry) {
      state.item.externalShutterArmorFabricShadeColorEntry = state.item.externalShutterArmorFabricShadeColorEntry ? state.item.externalShutterArmorFabricShadeColorEntry : externalShutterArmorFabricShadeColorEntry
    },
    setItemExternalShutterRunnerFabricShadeColorEntry(state, externalShutterRunnerFabricShadeColorEntry) {
      state.item.externalShutterRunnerFabricShadeColorEntry = state.item.externalShutterRunnerFabricShadeColorEntry ? state.item.externalShutterRunnerFabricShadeColorEntry : externalShutterRunnerFabricShadeColorEntry
    },
    setItemExternalShutterBoxInternalFabricShadeColorEntry(state, externalShutterBoxInternalFabricShadeColorEntry) {
      state.item.temporaryInternalBoxColor = state.item.temporaryInternalBoxColor ? state.item.temporaryInternalBoxColor : externalShutterBoxInternalFabricShadeColorEntry
    },
    setItemExternalShutterBoxExternalFabricShadeColorEntry(state, externalShutterBoxExternalFabricShadeColorEntry) {
      state.item.temporaryExternalBoxColor = state.item.temporaryExternalBoxColor ? state.item.temporaryExternalBoxColor : externalShutterBoxExternalFabricShadeColorEntry
    },
    setItemExternalShutterExternalShutterRunnerEntryLeft(state, externalShutterExternalShutterRunnerEntry) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterExternalShutterRunnerEntryLeft = it.externalShutterExternalShutterRunnerEntryLeft ? it.externalShutterExternalShutterRunnerEntryLeft : externalShutterExternalShutterRunnerEntry;
        })
      }
    },
    setItemExternalShutterExternalShutterRunnerEntryRight(state, externalShutterExternalShutterRunnerEntry) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterExternalShutterRunnerEntryRight = it.externalShutterExternalShutterRunnerEntryRight ? it.externalShutterExternalShutterRunnerEntryRight : externalShutterExternalShutterRunnerEntry;
        })
      }
    },

    setItemExternalShutterDrillHoleLeft(state, externalShutterDrillHole) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterDrillHoleLeft = it.externalShutterDrillHoleLeft ? it.externalShutterDrillHoleLeft : externalShutterDrillHole;
        })
      }
    },
    setItemExternalShutterDrillHoleRight(state, externalShutterDrillHole) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterDrillHoleRight = it.externalShutterDrillHoleRight ? it.externalShutterDrillHoleRight : externalShutterDrillHole;
        })
      }
    },

    setItemGlobalDrive(state, globalDrive) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.globalDrive = it.globalDrive ? it.globalDrive : globalDrive;
        })
      }
    },

    setItemGlobalDriveType(state, globalDriveType) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.globalDriveType = it.globalDriveType ? it.globalDriveType : globalDriveType;
        })
      }
    },
    setItemGlobalDriveSeries(state, globalDriveSeries) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.globalDriveSeries = it.globalDriveSeries ? it.globalDriveSeries : globalDriveSeries;
        })
      }
    },

    setItemGlobalDriveControl(state, globalDriveControl) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.globalDriveControl = it.globalDriveControl ? it.globalDriveControl : globalDriveControl;
        })
      }
    },

    setItemExternalShutterDriveExits(state, externalShutterDriveExit) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterDriveExit = it.externalShutterDriveExit ? it.externalShutterDriveExit : externalShutterDriveExit;
        })
      }
    },

    setItemExternalShutterAdaptation(state, externalShutterAdaptation) {
      if (state.item.externalShutterOrderItemCombinations) {
        state.item.externalShutterOrderItemCombinations.forEach((it) => {
          it.externalShutterAdaptation = it.externalShutterAdaptation ? it.externalShutterAdaptation : externalShutterAdaptation;
        })
      }
    },

    setMessage(state, payload) {
      state.message = payload;
    },
    toggleShowForm(state) {
      state.showForm = !state.showForm;
    },
    setIsMessageBorderAnimating(state, payload) {
      state.isMessageBorderAnimating = payload;
    },
    setExtraPackageNote(state, payload) {
      state.extraPackageNote = payload;
    },
    setPackageNote(state, payload) {
      state.packageNote = payload;
    },
    addError(state, payload) {
      state.errors.push({
        propertyPath: payload.field,
        message: payload.message
      });
    },
    deleteError(state, name) {
      const index = state.errors.findIndex(
        error => error.propertyPath === name
      );
      if (index > -1) {
        Vue.delete(state.errors, index);
      }
    },
    startLoading(state) {
      state.loading = true;
    },
    endLoading(state) {
      state.loading = false;
    },
    startGusFetching(state) {
      state.gusFetching = true;
    },
    endGusFetching(state) {
      state.gusFetching = false;
    },
    setOrder(state, order) {
      state.order = order;
    },
    saveItem(state) {
      let index = state.order.externalShutterOrderItems.findIndex(
        item => item.id === state.item.id
      );
      Vue.set(
        state.order.externalShutterOrderItems,
        index,
        simpleClone(state.item)
      );

    },
    addAutomationItem(state, automationItem) {
      state.order.automationOrderItems.push(simpleClone(automationItem))
    },
    addItem(state) {
      state.order.externalShutterOrderItems.push(simpleClone(state.item));
      state.item.shortDescription = null;
    },
    removeItem(state, index) {
      state.order.externalShutterOrderItems.splice(index, 1);
    },
    removeAutomationItem(state, index) {
      state.order.automationOrderItems.splice(index, 1);
    },
    updateCustomer(state, customer) {
      state.order.customer = customer;
    },
    setItem(state, {item}) {
      state.item = simpleClone(item);
    },
    editItem(state, {index, item}) {
      state.editIndex = index;
      item.externalShutterOrderItemCombinations.map(it => {
        const {globalDriveType, globalDriveSeries} = it.globalDrive
        it.globalDriveType = globalDriveType
        it.globalDriveSeries = globalDriveSeries
      })
      const findableExternalShutter = state.externalShutters.find(it => it["@id"] === item.externalShutter["@id"])
      state.item = {...item, ...{externalShutter: findableExternalShutter}};

    },
    resetEditIndex(state) {
      // Reset the edit index and clear relevant identifiers
      state.editIndex = null;
      state.item.id = null;
      state.item["@id"] = null;

      // Clear externalShutterOrderItemCombinations identifiers
      state.item.externalShutterOrderItemCombinations.forEach(combination => {
        combination["@id"] = null;
      });
    },

    setCombinationImage(state, payload) {
      state.combinationImage = payload
    },
    setCustomerByGusApiData(state, gusData) {
      state.order.customer.companyName = gusData.name;
      state.order.customer.taxId = gusData.nip;
      state.order.customer.zipCode = gusData.zipCode;
      state.order.customer.city = gusData.city;
      state.order.customer.streetAndNumber = `${gusData.street} ${gusData.propertyNumber}`;
      state.order.customer.firstName = null;
      state.order.customer.lastName = null;
      state.order.customer.phone = null;
    },
    resetCustomer(state) {
      if (state.order.customer.id) {
        delete state.order.customer.id;
        delete state.order.customer["@id"];
        delete state.order.customer["@type"];
      }
      state.order.customer.companyName = null;
      state.order.customer.taxId = null;
      state.order.customer.zipCode = null;
      state.order.customer.city = null;
      state.order.customer.streetAndNumber = null;
      state.order.customer.firstName = null;
      state.order.customer.lastName = null;
      state.order.customer.phone = null;
      state.order.customer.email = null;
    },
    setCustomer(state, customer) {
      state.order.customer = customer;
    },
    setExternalShutterOrderItems(state, items) {
      state.order.externalShutterOrderItems = items
    }
  },
  actions: {
    setMessage({commit}, payload) {
      commit("setMessage", payload);
    },
    toggleShowForm({commit}) {
      commit("toggleShowForm");
    },
    setIsMessageBorderAnimating({commit}, payload) {
      commit("setIsMessageBorderAnimating", payload);
    },
    setExtraPackageNote({commit}, payload) {
      commit("setExtraPackageNote", payload);
    },
    setPackageNote({commit}, payload) {
      commit("setPackageNote", payload);
    },
    deleteError({commit}, errorName) {
      commit("deleteError", errorName);
    },
    cancelEdit({commit, dispatch}) {
      commit("resetEditIndex");
      //commit("resetChangeStatusesForEditItem");
      dispatch("setItemDefaults");
    },
    increaseCombinationsInItem({commit}, payload) {
      if (Number.isInteger(payload)) {
        commit("increaseCombinationInItems", payload);
      }
    },
    setCombinationImage({commit}, payload) {
      commit("setCombinationImage", payload);
    },
    setItemDefaults({commit, getters}) {
      const firstIndex = 0;

      const defaultItem = {
        externalShutter: simpleClone(getters.externalShutters[firstIndex]),
        globalPipe: simpleClone(getters.externalShutters[firstIndex].globalPipes[firstIndex]),
        externalShutterBox: simpleClone(getters.externalShutters[firstIndex].externalShutterBoxes[firstIndex]),
        externalShutterBoxInternalFabricShadeColorEntry: simpleClone(getters.externalShutters[firstIndex].externalShutterBoxes[firstIndex].externalShutterBoxInternalFabricShadeColorEntries[firstIndex]),
        externalShutterBoxExternalFabricShadeColorEntry: simpleClone(getters.externalShutters[firstIndex].externalShutterBoxes[firstIndex].externalShutterBoxExternalFabricShadeColorEntries[firstIndex]),
        externalShutterPriceList: simpleClone(getters.externalShutters[firstIndex].externalShutterPriceLists[firstIndex]),
        externalShutterArmorExternalShutterArmorColorEntry: simpleClone(getters.externalShutters[firstIndex].externalShutterPriceLists[firstIndex].externalShutterArmor.externalShutterArmorExternalShutterArmorColorEntries[firstIndex]),
        externalShutterArmorFabricShadeColorEntry: simpleClone(getters.externalShutters[firstIndex].externalShutterPriceLists[firstIndex].externalShutterArmor.externalShutterArmorFabricShadeColorEntries[firstIndex]),
        externalShutterExternalShutterRunnerEntryLeft: simpleClone(getters.externalShutters[firstIndex].externalShutterExternalShutterRunnerEntries[firstIndex]),
        externalShutterExternalShutterRunnerEntryRight: simpleClone(getters.externalShutters[firstIndex].externalShutterExternalShutterRunnerEntries[firstIndex]),
        externalShutterRunnerFabricShadeColorEntry: simpleClone(getters.externalShutters[firstIndex].externalShutterExternalShutterRunnerEntries[firstIndex].externalShutterRunner.externalShutterRunnerFabricShadeColorEntries[firstIndex]),
        externalShutterDrillHoleLeft: simpleClone(getters.externalShutters[firstIndex].externalShutterExternalShutterRunnerEntries[firstIndex].externalShutterRunner.externalShutterDrillHoles[firstIndex]),
        externalShutterDrillHoleRight: simpleClone(getters.externalShutters[firstIndex].externalShutterExternalShutterRunnerEntries[firstIndex].externalShutterRunner.externalShutterDrillHoles[firstIndex]),
        //globalDrive: simpleClone(getters.externalShutters[firstIndex].globalPipes[firstIndex].globalDrives[firstIndex]),
        //globalDriveType: simpleClone(getters.externalShutters[firstIndex].globalPipes[firstIndex].globalDrives[firstIndex].globalDriveType),
        //globalDriveSeries: simpleClone(getters.externalShutters[firstIndex].globalPipes[firstIndex].globalDrives[firstIndex].globalDriveSeries),
        //globalDriveControls: simpleClone(getters.externalShutters[firstIndex].globalPipes[firstIndex].globalDrives[firstIndex].globalDriveControls[firstIndex]),
        // globalRemoteControl: simpleClone(getters.externalShutters[firstIndex].globalPipes[firstIndex].globalDrives[firstIndex].globalRemoteControls[firstIndex]),
        externalShutterDriveExits: simpleClone(getters.externalShutters[firstIndex].externalShutterDriveExits[firstIndex]),
        externalShutterAdaptation: simpleClone(getters.externalShutters[firstIndex].externalShutterBoxes[firstIndex].externalShutterAdaptations[firstIndex])
      }

      if (defaultItem.externalShutter) {

        commit("setItemGlobalPipe", defaultItem.globalPipe)
        commit("setItemExternalShutter", defaultItem.externalShutter);
        commit("setItemExternalShutterBox", defaultItem.externalShutterBox);
        commit("setItemExternalShutterPriceList", defaultItem.externalShutterPriceList);
        commit("setItemExternalShutterRunnerFabricShadeColorEntry", defaultItem.externalShutterRunnerFabricShadeColorEntry)
        commit("setItemExternalShutterArmorExternalShutterArmorColorEntry", defaultItem.externalShutterArmorExternalShutterArmorColorEntry)
        commit("setItemExternalShutterArmorFabricShadeColorEntry", defaultItem.externalShutterArmorFabricShadeColorEntry)

        commit("setItemExternalShutterExternalShutterRunnerEntryLeft", defaultItem.externalShutterExternalShutterRunnerEntryLeft)
        commit("setItemExternalShutterExternalShutterRunnerEntryRight", defaultItem.externalShutterExternalShutterRunnerEntryRight)

        commit("setItemExternalShutterDrillHoleLeft", defaultItem.externalShutterDrillHoleLeft)
        commit("setItemExternalShutterDrillHoleRight", defaultItem.externalShutterDrillHoleRight)

        //commit("setItemGlobalDrive", defaultItem.globalDrive)
        //commit("setItemGlobalDriveType", defaultItem.globalDriveType)
        //commit("setItemGlobalDriveSeries", defaultItem.globalDriveSeries)
        //commit("setItemGlobalDriveControl", defaultItem.globalDriveControls)

        commit("setItemExternalShutterDriveExits", defaultItem.externalShutterDriveExits)
        commit("setItemExternalShutterAdaptation", defaultItem.externalShutterAdaptation)

        commit("setItemExternalShutterBoxInternalFabricShadeColorEntry", defaultItem.externalShutterBoxInternalFabricShadeColorEntry);
        commit("setItemExternalShutterBoxExternalFabricShadeColorEntry", defaultItem.externalShutterBoxExternalFabricShadeColorEntry);
      }

    },
    async saveCustomer({commit, state}, type = "create") {
      commit("startLoading");
      let error = {field: null, message: null};
      let isError = false;
      if (state.order.customer.type === "person") {
        if (!state.order.customer.lastName || state.order.customer.lastName === "") {
          error.field = "lastName";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_last_name_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "lastName");
        }
        if (!state.order.customer.city || state.order.customer.city === "") {
          error.field = "city";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_city_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "city");
        }
        if (isError) {
          return;
        }
      } else if (state.order.customer.type === "company") {
        if (
          !state.order.customer.companyName ||
          state.order.customer.companyName === "" ||
          !state.order.customer.taxId ||
          state.order.customer.taxId === "" ||
          !state.order.customer.city ||
          state.order.customer.city === ""
        ) {
          if (
            !state.order.customer.companyName ||
            state.order.customer.companyName === ""
          ) {
            error.field = "companyName";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!state.order.customer.taxId || state.order.customer.taxId === "") {
            error.field = "taxId";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!state.order.customer.city || state.order.customer.city === "") {
            error.field = "city";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
          commit("endLoading");
          return;
        } else {
          if (state.order.customer.companyName && state.order.customer.companyName !== "") {
            commit("deleteError", "companyName");
          }
          if (state.order.customer.taxId && state.order.customer.taxId !== "") {
            commit("deleteError", "texId");
          }
        }
      }
      if (type === "create" && state.order.customer.id) {
        delete state.order.customer.id;
        delete state.order.customer["@id"];
        delete state.order.customer["@type"];
      }
      let method = type === "create" ? "post" : "put";
      let url = type === "create" ? "/customers" : `/customers/${state.order.customer.id}`;
      await http[method](url, state.order.customer)
        .then(response => {
          const data = response.data;
          notify(t.t("customer_updated"));
          commit("updateCustomer", data);
        })
        .catch(error => {
          const resp = error.response;
          if (resp) {
            if (resp.status === 400) {
              const violations = resp.data.violations;
              violations.forEach(violation => {
                notify(
                  `${t.t("field")} ${t.t(violation.propertyPath)} ${t.t(
                    violation.message
                  )}`,
                  "warning"
                );
              });
            }
          }
        });
      commit("endLoading");
    },
    async removeItem({commit, dispatch, state}, index) {
      commit("removeItem", index);
      if (state.editIndex === index) {
        dispatch("setItemDefaults");
      }
      await dispatch("updateOrder");
    },
    async removeAutomationItem({commit, dispatch, state}, index) {
      commit("removeAutomationItem", index);
      if (state.editIndex === index) {
        dispatch("setItemDefaults");
      }
      await dispatch("updateOrder");
    },
    async saveItemAndClose({dispatch}) {
      dispatch("saveItem").then(dispatch("valuateOrder"));
    },
    async saveAutomationItem({commit, dispatch}, item) {
      commit("addAutomationItem", item);
      notify("Dodano pozycję do zamówienia");

      await dispatch("updateOrder");
    },
    async saveItem({commit, dispatch, getters}) {
      if (getters.isEdit) {
        commit("saveItem");
        dispatch("setItemDefaults");
        commit("setItemCombinationsCount", 1)
        commit("resetEditIndex");
        notify("Zmieniono pozycję zamówienia");
      } else {
        commit("addItem");
        commit("setItemCombinationsCount", 1)
        notify("Dodano pozycję do zamówienia");
      }
      await dispatch("updateOrder");
    },
    async clearOrder({state, dispatch, commit}) {
      let order = simpleClone(state.order);
      dispatch("updateClearOrder", {order})
      state.order.externalShutterOrderItems = [];
      state.order.automationOrderItems = [];

      state.order.netSalesValue = 0;
      state.order.salesGrossValue = 0;
      state.order.totalSalesNetValue = 0;
      state.order.leftToPayValue = 0;
      state.order.netSalesValueAfterDiscount = 0;
      state.order.percentDiscount = 0;
      state.order.withInstallation = false;
      state.order.installationValue = 0;
      state.order.installationValuePerItem = 0;
      state.order.fitterTravelCost = null;
      state.order.fitterTravelCostValue = 0;
      state.order.advanceValue = 0;
      state.order.discountValue = 0;
      state.order.packageNote = "";
      state.order.notes = "";

      commit("resetCustomer")
      commit("setExtraPackageNote", "");

      state.editIndex = null;
      dispatch("updateOrder");
    },
    updateClearOrder: async function (state, {order}) {
      const orderData = prepareOrderToSent(order);
      orderData.customer = null
      http.put(`/orders/${order.id}/clear`, orderData);
    },
    updateOrder: debounce(async function ({commit, getters, rootState}) {
      // commit("common/startLoading", null, {root: true})
      let order = simpleClone(getters.order);
      let customer = simpleClone(getters.order.customer);
      if (!customer.id || !customer["@id"]) {
        order.customer = null;
      }
      order = prepareOrderToSent(order);

      if (!order.number) {
        order.number = order.id;
      }

      if (rootState.company.buyType === "wholesale") {
        order.customer = null;
      }

      let {data} = await http.put(`/orders/${order.id}`, order);

      if (data.percentDiscount !== order.percentDiscount) {
        commit("addError", {
          field: "percentDiscount",
          message: "percent_discount_exceeded"
        });
      } else {
        commit("deleteError", "percentDiscount");
      }

      if (data.percentDiscountAutomation !== order.percentDiscountAutomation) {
        commit("addError", {
          field: "percentDiscountAutomation",
          message: "percent_discount_exceeded"
        });
      } else {
        commit("deleteError", "percentDiscountAutomation");
      }

      if (!customer.id || !customer["@id"]) {
        data.customer = customer;
      }
      commit("setOrder", data);
      // commit("common/endLoading", null, {root: true})
    }, 1000),
    async placeOrder(
      {state, commit, dispatch, rootState, rootGetters},
      isEditing = false
    ) {

      if (state.order.externalShutterOrderItems.length === 0) {
        notify(t.t("order_cant_be_empty"), "warning");
        return;
      }

      commit("startLoading");
      let order = simpleClone(state.order);
      order = prepareOrderToSent(order);
      if (!order.number) {
        order.number = order.id;
      }

      let error = {field: null, message: null};
      let isError = false;
      if (order.customer.type === "person") {
        if (!order.customer.lastName || order.customer.lastName === "") {
          error.field = "lastName";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_last_name_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "lastName");
        }
        if (!order.customer.city || order.customer.city === "") {
          error.field = "city";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_city_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "city");
        }
        if (isError) {
          return;
        }
      } else if (order.customer.type === "company") {
        if (
          !order.customer.companyName ||
          order.customer.companyName === "" ||
          !order.customer.taxId ||
          order.customer.taxId === "" ||
          !order.customer.city ||
          order.customer.city === ""
        ) {
          if (
            !order.customer.companyName ||
            order.customer.companyName === ""
          ) {
            error.field = "companyName";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.taxId || order.customer.taxId === "") {
            error.field = "taxId";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.city || order.customer.city === "") {
            error.field = "city";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
          commit("endLoading");
          return;
        } else {
          if (order.customer.companyName && order.customer.companyName !== "") {
            commit("deleteError", "companyName");
          }
          if (order.customer.taxId && order.customer.taxId !== "") {
            commit("deleteError", "texId");
          }
        }
      }
      if (rootState.company.buyType === "wholesale") {
        order.customer = null;
      }

      let note = order.packageNote || "";

      if (state.extraPackageNote) {
        if (order.packageNote) note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
        note = state.extraPackageNote + "." + note;
      }

      order.packageNote = note;

      const {data} = await http.put(
        `/orders/${state.order.id}/complete`,
        order
      );
      commit("setExtraPackageNote", "");
      if (data.status === "new") {
        // redirect to order show
      }

      if (isEditing) {
        await router.push({name: "external_shutters_create_order"});
      } else {
        // asking api for draft order as on launch and set defaults values for item after placing order
        dispatch("getOrder");
        dispatch("setItemDefaults");
      }
      commit("endLoading");
      notify(t.t("new_complete_order_added"));

      notify(t.t("new_complete_order_added_thanks", {orderId: orderNumberCreator(order.id)}) + "<br>" +
        t.t("new_complete_order_added_price", {price: rootGetters["common/formatPrice"](order.totalPurchaseGrossValue)}) + "<br>" +
        t.t('new_complete_order_added_more'), "success", "is-top", 7000);

    },
    async valuateOrder(
      {state, commit, dispatch, rootState},
      isEditing = false
    ) {
      if (state.order.externalShutterOrderItems.length === 0) {
        notify(t.t("order_cant_be_empty"), "warning");
        return;
      }

      commit("startLoading");
      let order = simpleClone(state.order);
      order = prepareOrderToSent(order);

      if (!order.number) {
        order.number = order.id;
      }

      let error = {field: null, message: null};
      let isError = false;
      if (order.customer.type === "person") {
        if (!order.customer.lastName || order.customer.lastName === "") {
          error.field = "lastName";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_last_name_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "lastName");
        }
        if (!order.customer.city || order.customer.city === "") {
          error.field = "city";
          error.message = "value_shouldnt_be_empty";
          commit("addError", error);
          notify(t.t("customer_city_is_empty"), "warning");
          commit("endLoading");
          isError = true;
        } else {
          commit("deleteError", "city");
        }
        if (isError) {
          return;
        }
      } else if (order.customer.type === "company") {
        if (
          !order.customer.companyName ||
          order.customer.companyName === "" ||
          !order.customer.taxId ||
          order.customer.taxId === "" ||
          !order.customer.city ||
          order.customer.city === ""
        ) {
          if (
            !order.customer.companyName ||
            order.customer.companyName === ""
          ) {
            error.field = "companyName";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.taxId || order.customer.taxId === "") {
            error.field = "taxId";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          if (!order.customer.city || order.customer.city === "") {
            error.field = "city";
            error.message = "value_shouldnt_be_empty";
            commit("addError", error);
          }
          notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
          commit("endLoading");
          return;
        } else {
          if (order.customer.companyName && order.customer.companyName !== "") {
            commit("deleteError", "companyName");
          }
          if (order.customer.taxId && order.customer.taxId !== "") {
            commit("deleteError", "texId");
          }
        }
      }

      if (rootState.company.buyType === "wholesale") {
        order.customer = null;
      }

      let note = order.packageNote || "";

      if (state.extraPackageNote) {
        if (order.packageNote) note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
        note = state.extraPackageNote + "." + note;
      }

      order.packageNote = note;


      const {data} = await http.put(
        `/orders/${state.order.id}${isEditing ? "" : "/valuation"}`,
        order
      );
      commit("setExtraPackageNote", "");
      if (data.status === "new") {
        // redirect to order show
      }

      if (isEditing) {
        await router.push({name: "external_shutters_create_order"});
      } else {
        // asking api for draft order as on launch and set defaults values for item after placing order
        dispatch("getOrder");
        dispatch("setItemDefaults");
      }

      commit("endLoading");
      notify(isEditing ? t.t("valuation_saved") : t.t("new_valuation_added"));
    },

    async getOrderForCopy({dispatch, commit}, id = null) {
      try {
        const order = (await http.get(`/orders/${id}`)).data;
        //wait for all recalculate, so use it as promise
        await Promise.all(
          order.externalShutterOrderItems.map(item => {
              delete item.id;
              item.externalShutterOrderItemCombinations.forEach(combination => {
                delete combination.id;
              });
              return dispatch("recalculateOrderItem", item);
            }
          ));

        commit("setExternalShutterOrderItems", order.externalShutterOrderItems);
        commit("setCustomer", order.customer);

        dispatch("updateOrder");
      } catch (e) {
        notify(t.t("order_copy_error", {id}), "error", "is-top", 7000);
      }

    },
    async recalculateOrderItem({state}, item) {
      state.priceLoading = true;
      item = simpleClone(item);
      item = prepareItemToSent(item);
      delete item["@id"];
      delete item.id;
      await http.post(`/calculate/external_shutter_order_item`, item)
        .then(({data}) => {
          item.netPurchaseValue = data.netPurchaseValue;
          item.netSalesValue = data.netSalesValue;
        })
        .catch(error => {
          notify(error.response.data["hydra:description"], "danger");
        });
    },
    async getOrder({commit, rootGetters}, id = null) {
      //check if id is set, if yes, its valuation edit mode
      let order = null;
      if (id !== null && !isNaN(id)) {
        let response = await http.get(`/orders/${id}`);
        order = response.data;
      } else {
        // find drafts
        let {data} = await http.get(
          `/orders?${store.getters["auth/companyTypeForUrlProp"]}.id=${store.getters["auth/companyId"]}&status=draft&category=external_shutter&properties[]=id`
        );
        // get first draft by id as order
        if (data["hydra:member"].length > 0) {
          let first = data["hydra:member"][0];
          let response = await http.get(`/orders/${first.id}`);
          order = response.data;
        } else {
          let params = {
            category: "external_shutter",
            customer: null
          };
          // create new order
          let response = await http.post("/orders", params);
          order = response.data;
        }
        if (order.customer === null) {
          order.customer = {
            type: "person",
            companyName: null,
            firstName: null,
            lastName: null,
            zipCode: null,
            city: null,
            streetAndNumber: null,
            phone: null,
            email: null,
            taxId: null
          };
        }
        if (!order.name) {
          order.name = `Wycena numer ${orderNumberCreator(order.id)}`;
        }
        if (
          !order.companyVatRate &&
          objectToCollection(rootGetters["company/companyVatRates"]).length > 0
        ) {
          let first = null;
          for (const rate in rootGetters["company/companyVatRates"]) {
            if (first === null) {
              first = rate;
            }
            if (
              rootGetters["company/companyVatRates"][rate].name.includes(
                "23"
              ) ||
              rootGetters["company/companyVatRates"][rate].value
                .toString()
                .includes("23")
            ) {
              order.companyVatRate =
                rootGetters["company/companyVatRates"][rate]["@id"];
              break;
            }
          }
          if (!order.companyVatRate) {
            order.companyVatRate =
              rootGetters["company/companyVatRates"][first]["@id"];
          }
        }

        if (
          rootGetters["company/defaultDeliveryMethod"] &&
          !order.deliveryMethod &&
          objectToCollection(rootGetters["company/deliveryMethods"]).length > 0
        ) {
          let first = null;
          for (const delivery in rootGetters["company/deliveryMethods"]) {
            if (first === null) {
              first = delivery;
            }
            if (
              rootGetters["company/deliveryMethods"][delivery]["@id"] ===
              rootGetters["company/defaultDeliveryMethod"]
            ) {
              order.deliveryMethod =
                rootGetters["company/deliveryMethods"][delivery];
              break;
            }
          }
          if (!order.deliveryMethod) {
            order.deliveryMethod =
              rootGetters["company/deliveryMethods"][first];
          }
        }

        if (
          !order.paymentMethod &&
          objectToCollection(rootGetters["company/paymentMethods"]).length > 0
        ) {
          for (const cost in rootGetters["company/paymentMethods"]) {
            order.paymentMethod = rootGetters["company/paymentMethods"][cost];
            break;
          }
        }
      }
      commit("setOrder", order);
    },
    async init({commit, dispatch}) {
      // commit("startLoading");
      let [
        externalShutters
      ] = await Promise.all([
        http.get(
          "/external_shutters?" +
          generateProperties([],
            ["id",
              "name",
              "minWidth",
              'externalShutterDriveExits',
              "measurementInstruction",
              "surveyPicture",
              "surveyDescriptionPL",
              "surveyDescriptionCZ",
              "surveyDescriptionEN",
              "surveyDescriptionDE",
              "surveyDescriptionFR",
              "surveyDescriptionSE",
              "surveyDescriptionIT",
              "surveyDescriptionSI",
              "surveyDescriptionRU",
              "surveyDescriptionES",
              "surveyDescriptionNL"]) +
          generateProperties(
            ["externalShutterBoxes", "externalShutterAdaptations"],
            ["id", "name", "hasMosquitoNet", 'sectionPicture'], true
          ) +
          generateProperties(
            ["externalShutterBoxes"],
            ["id", "name", "hasMosquitoNet", "height", "width", 'sectionPicture', 'isEnabled'], true
          ) +
          generateProperties(
            ["externalShutterBoxes", 'externalShutterBoxExternalFabricShadeColorEntries'],
            ["id"],
            true
          ) +
          generateProperties(
            ["externalShutterBoxes", 'externalShutterBoxInternalFabricShadeColorEntries'],
            ["id"],
            true
          ) +
          generateProperties(
            ["externalShutterBoxes", 'externalShutterBoxExternalFabricShadeColorEntries', 'fabricShadeColor'],
            ["id", "name"],
            true
          ) +
          generateProperties(
            ["externalShutterBoxes", 'externalShutterBoxInternalFabricShadeColorEntries', 'fabricShadeColor'],
            ["id", 'name'],
            true
          ) +
          generateProperties(
            ["externalShutterBoxes", "externalShutterPipeExternalShutterArmorEntries"],
            ["id", "value", "externalShutterArmor", "globalPipe"],
            true) +
          generateProperties(
            ["externalShutterBoxes", "externalShutterPipeExternalShutterArmorEntries", "externalShutterArmor"],
            ["id"],
            true) +
          generateProperties(
            ["externalShutterBoxes", "externalShutterPipeExternalShutterArmorEntries", "globalPipe"],
            ["id"],
            true) +
          generateProperties(
            ["externalShutterPriceLists", "externalShutterArmor"],
            ["id", "name", "thumbnail", "sectionPicture", "maxWidth", "weight"],
            true
          ) +
          generateProperties(
            [
              "externalShutterPriceLists",
              "externalShutterArmor",
              "externalShutterArmorExternalShutterArmorColorEntries"
            ],
            ["id"],
            true
          ) +
          generateProperties(
            [
              "externalShutterPriceLists",
              "externalShutterArmor",
              "externalShutterArmorExternalShutterArmorColorEntries",
              "externalShutterArmorColor"
            ],
            ["id", "name", "thumbnail", "sectionPicture"],
            true
          ) +
          generateProperties(
            [
              "externalShutterPriceLists",
              "externalShutterArmor",
              "externalShutterArmorFabricShadeColorEntries"
            ],
            ["id"],
            true
          ) +
          generateProperties(
            [
              "externalShutterPriceLists",
              "externalShutterArmor",
              "externalShutterArmorFabricShadeColorEntries",
              "fabricShadeColor"
            ],
            ["id", "name", "thumbnail", "sectionPicture"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterRunnerEntries"],
            ["id"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterRunnerEntries", "externalShutterRunner"],
            ["id", "name", "isRunnerUnderMosquitoNet", "sectionLeftPicture", "sectionRightPicture"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterRunnerEntries", "externalShutterRunner", "externalShutterRunnerFabricShadeColorEntries"],
            ["id"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterRunnerEntries", "externalShutterRunner", "externalShutterRunnerFabricShadeColorEntries", "fabricShadeColor"],
            ["id", "name"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterRunnerEntries", "externalShutterRunner", "externalShutterDrillHoles"],
            ["id", "name", "sectionLeftPicture", "sectionRightPicture"],
            true
          ) +
          generateProperties(
            ["globalPipes"],
            ["id", "name", "symbol", "sectionPicture", "externalShutterMaxWidth"],
            true
          ) +
          generateProperties(
            ["globalPipes", "globalDrives"],
            ["id", "name", "picture", "thumbnail", "message", "minWidth", "maxWidth", "liftingCapacity", "liftingCapacityNm", "pieceSurcharge", "externalShutterGlobalDriveEntries"],
            true
          ) +
          generateProperties(
            ["globalPipes", "globalDrives", "globalDriveType"],
            ["id", "name", "message", "symbol"],
            true
          ) +
          generateProperties(
            ["globalPipes", "globalDrives", "globalDriveSeries"],
            ["id", "name", "symbol"],
            true
          ) +
          // generateProperties(
          //     ["globalPipes", "globalDrives", "globalRemoteControls"],
          //     ["id", "name", "picture", "thumbnail", "message"],
          //     true
          // ) +
          generateProperties(
            ["globalPipes", "globalDrives", "globalDriveControls"],
            ["id", "name", "picture", "thumbnail", "pieceSurcharge", "isEnabled", "message", "message", "symbol", "manufacturer", "globalDriveControlType", "globalRemoteControlChannel", "isRemoteControl", "description"],
            true
          ) +
          generateProperties(
            ["globalPipes", "globalDrives", "globalDriveControls", "manufacturer"],
            ["id", "name"]
            , true)
          +
          generateProperties(
            ["globalPipes", "globalDrives", "globalDriveControls", "globalDriveControlType"],
            ["id", "name"]
            , true)
          +
          generateProperties(
            [
              "globalPipes",
              "globalDrives",
              "globalDriveControls",
              "globalRemoteControls"
            ],
            ["id", "name", "picture", "thumbnail", "message"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterProtectionEntries"],
            ["id"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterProtectionEntries", "externalShutterProtection"],
            ["id", "name", "picture"],
            true
          ) +
          generateProperties(
            ["externalShutterExternalShutterProtectionEntries", "externalShutterProtection", "externalShutterProtectionConflicts"],
            ["id", "name"],
            true
          )
        )
      ]);


      commit("setExternalShutters", externalShutters.data["hydra:member"]);
      dispatch("setItemDefaults");
      // commit("endLoading");
    },
    async fetchGus({state, commit}) {
      const nip = state.order.customer.taxId
        .replace(/^\D+/g, "")
        .replaceAll("-", "");
      commit("startGusFetching");
      try {
        let {data} = await http.get(`/gus/nip/${nip}`);
        commit("setCustomerByGusApiData", data);
      } catch (e) {
        commit("endGusFetching");
        window.alert(t.t("gus_problem"));
      }
      commit("endGusFetching");
    },
    resetCustomer({commit}) {
      commit("resetCustomer");
    }
  }
};
