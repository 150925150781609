<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="company_installation_values" :transform-to-submit="transformToSubmit" :action-after-save="actionAfterSave")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(:label="$t('general')")
          .columns
            .column
              app-form-field(:label="$t('name')" field="name" :errors="errors")
                b-input(v-model="data.name")
            .column
              app-form-field(:label="$t('value')" field="value" :errors="errors")
                app-form-decimal(v-model="data.value")  
        b-tab-item(:label="$t('product_types.fabric_shade')")
          multiple-resource-select(
            v-model="data.fabricShades"
            resource="fabric_shades"
            url="fabric_shades"
            extra-props="properties[]=id&properties[]=name"
          )
        b-tab-item(:label="$t('product_types.fabric_shade_day_night')")
          multiple-resource-select(
            v-model="data.fabricShadeDayNights"
            resource="fabric_shade_day_nights"
            url="fabric_shade_day_nights"
            extra-props="properties[]=id&properties[]=name"
          )
        b-tab-item(:label="$t('product_types.external_shutter')")
          multiple-resource-select(
            v-model="data.externalShutters"
            resource="external_shutters"
            url="external_shutters"
            extra-props="properties[]=id&properties[]=name"
          )
        b-tab-item(:label="$t('product_types.pleat')")
          multiple-resource-select(
            v-model="data.pleats"
            resource="pleats"
            url="pleats"
            extra-props="properties[]=id&properties[]=name"
          )
        b-tab-item(:label="$t('product_types.detail')")
          multiple-resource-select(
            v-model="data.details"
            resource="details"
            url="details"
            extra-props="properties[]=id&properties[]=name"
          )


</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import { mapActions } from "vuex";

export default {
  mixins: [form],
  components: {
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        value: 0,
        fabricShades: [],
        fabricShadeDayNights: [],
        externalShutters: []
      }
    };
  },
  methods: {
    ...mapActions("company", [
      "updateCompanyInstallationValue",
      "addCompanyInstallationValue"
    ]),
    transformToSubmit(data) {

      // data.fabricShades = data.fabricShades.map(fs => fs["@id"]);
      return data;
    },
    actionAfterSave(data, method) {
      if (method === "post") {
        this.addCompanyInstallationValue(data);
      } else if (method === "put") {
        this.updateCompanyInstallationValue(data);
      }
    }
  }
};
</script>
