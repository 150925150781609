<template lang="pug">
  fragment
    td
      app-form-hover-image(:image="image(row, 'picture')")
    td
      .columns.is-mobile.is-desktop
        .column.is-full-mobile.is-6-tablet.is-10-desktop
          p.is-full {{ row.name }}
        .column.is-full-mobile.is-6-tablet.is-1-desktop
          i(class="fas fa-info-circle has-pointer is-full" @click="openDetailsModal()")
    td {{ row.symbol }}
    td
      v-select.w100.has-pointer(
        :options="row.detailUnits"
        label="name"
        v-model="detailUnit"
        :clearable="false"
        @input="onUnitChange"
        :placeholder="$t('empty')"
      )
        template(v-slot:no-options='') {{$t('empty')}}
    td
      p {{ decimal(selectedUnitPrice) }}
    td.max-table-col-width-medium
      app-form-field(
        field="quantity"
        :show-help="showQuantityHelp"
      )
        b-numberinput(
          v-model="quantity"
          :min="row.minOrderQuantity"
          :max="row.maxUnitOrderQuantity"
          :step="row.quantityChangeStep"
          @input="onQuantityChange"
        )
    td.max-table-col-width-small
      app-form-field(
        v-if="detailUnit && detailUnit.symbol === 'mb'"
        field="pieces"
      )
        app-form-length(
          v-model="pieces"
          :unit="lengthUnit"
        )
      app-form-field(
        v-else-if="detailUnit && detailUnit.symbol === 'rozpak'"
        field="pieces"
        :show-help="showPiecesHelp"
        :help="$t('detail_list.help_unit_pieces')"
      )
        b-input(:value="formattedPieces(pieces)" size="is-small" @focus="()=>showPiecesHelp = true"
          @blur="()=>showPiecesHelp = false" readOnly="true")
      app-form-field(
        v-else-if="detailUnit && detailUnit.symbol === 'opak'"
        field="pieces"
        :show-help="showPiecesHelp"
        :help="$t('detail_list.help_package_pieces')"
      )
        b-input(:value="formattedPieces(pieces)" size="is-small" @focus="()=>showPiecesHelp = true"
          @blur="()=>showPiecesHelp = false" readOnly="true")
      app-form-field(
        v-else
        field="pieces"
      )
        b-input(:value="formattedPieces(pieces)" size="is-small" @focus="()=>showPiecesHelp = true"
          @blur="()=>showPiecesHelp = false" readOnly="true")
    td.max-table-col-width-small.has-text-centered.is-center {{ unitName({detailUnit, metricUnit:detail.metricUnit}) }}
    td {{ sumPieces }}
    td(v-if="!hideWholeSalePrice" ) {{ priceFormatter(decimal(sumPricesPerItem))}} {{ currencyCode }}
    td
      b-button(type="is-primary" :disabled="!isAddAvailable" @click="addItemToOrder()") {{ $t('add') }}

</template>
<script>
import simpleClone from "../../../../helpers/simpleClone";
import decimal from "../../../../helpers/decimal";
import {mapActions, mapGetters} from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";
import unitName from "../../helpers/unitName";
import DetailInfoModal from "./DetailInfoModal.vue";

export default {
  components: {DetailInfoModal},
  props: {
    row: Object,
    value: Object,
  },
  data() {
    return {
      showModal: false,
      quantity: 0,
      detail: simpleClone(this.row),
      pieces: null,
      netPurchaseValue: 0,
      netSalesValue: 0,
      companyInstallationValue: null,
      shortDescription: null,
      detailUnit: null,
      metricUnit: null,
      showPiecesHelp: false,
      showQuantityHelp: false,
      isAddAvailable: true,
    };
  },
  created() {
    this.setDefault(this.row);
  },
  methods: {
    onQuantityChange() {
      this.checkMinAndMaxQuantityValid();
    },
    formattedPieces(pieces) {
      return parseInt(pieces / 100)
    },
    checkMinAndMaxQuantityValid() {
      if (this.quantity < this.row.minOrderQuantity) {
        this.isAddAvailable = false;
        return;
      } else if (this.quantity > this.row.maxUnitOrderQuantity) {
        this.isAddAvailable = false;
        return;
      } else if (this.quantity % this.row.quantityChangeStep !== 0) {
        this.isAddAvailable = false;
        return;
      }
      this.isAddAvailable = true
    },
    unitName,
    ...mapActions("details", ["saveItem"]),
    addItemToOrder: function () {
      this.saveItem({
        detail: this.row,
        quantity: this.quantity,
        detailUnit: this.detailUnit,
        pieces: this.pieces,
      });
    },
    priceFormatter,
    decimal,
    onUnitChange: function () {
      this.setDefault(this.row);
    },
    setFirstDetailUnit: function () {
      if (!this.detailUnit && this.row.detailUnits.length > 0) {
        this.detailUnit = this.row.detailUnits[0];
      }
    },
    setPieces: function () {
      if (this.detailUnit && this.detailUnit.symbol === 'mb') {
        this.pieces = 1000;
      } else if (this.detailUnit && this.detailUnit.symbol === 'rozpak') {
        this.pieces = this.row.quantityChangeStep * 100;
      } else if (this.detailUnit && this.detailUnit.symbol === 'opak') {
        this.pieces = this.row.packageQuantity * 100;
      } else {
        this.pieces = 100;
      }
    },
    setDefault(row) {
      this.setFirstDetailUnit();
      this.setPieces();
      this.quantity = row.minOrderQuantity;
    },
    openDetailsModal() {
      this.$emit("input", this.row);
    },
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("details", ["image", "item"]),
    ...mapGetters(["hideWholeSalePrice"]),
    sumPricesPerItem() {
      const {detailUnit} = this;

      if (!detailUnit || !detailUnit.symbol) {
        return;
      }

      switch (detailUnit.symbol) {
        case 'rozpak':
          return (
              (this.quantity * this.row.netWholesalePriceForUnit * this.pieces) /
              100
          );
        case 'opak':
          return (
              (this.quantity * this.row.netWholesalePriceForPackage * this.pieces) /
              100
          );
        case 'mb':
          return (
              (this.quantity * this.row.netWholesalePriceForCut * this.pieces) /
              1000
          );
        default:
          return (
              (this.quantity * this.row.netWholesalePriceForUnit * this.pieces) /
              100
          );
      }
    },
    sumPieces() {
      if (this.detailUnit && this.detailUnit.symbol === 'mb') {
        return (
            (this.pieces / 1000 * this.quantity).toFixed(2) + ' mb'
        );
      }
      return this.pieces / 100 * this.quantity;
    },
    selectedUnitPrice() {
      const {detailUnit} = this;

      if (!detailUnit || !detailUnit.symbol) {
        return;
      }

      switch (detailUnit.symbol) {
        case 'rozpak':
          return this.row.netWholesalePriceForUnit;
        case 'opak':
          return this.row.netWholesalePriceForPackage;
        case 'mb':
          return this.row.netWholesalePriceForCut;
        default:
          return this.row.netWholesalePriceForUnit;
      }
    },
  },
};
</script>
