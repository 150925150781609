<template lang="pug">
  b-modal(v-model="isActive" @close="onClose" :width="1400")
    app-view-loadable(:loading="loading")
      app-view-card(:title="order && order.status === 'valuation' ? $t('valuation_preview') :  $t('order_preview')")
        template(slot="icon")
          b-button(
            @click="onClose"
            size="is-small"
          )
            b-icon(icon="close" size="is-small")
        .is-centered(v-if="!order || (order && orderItems().length < 1)") {{ $t('empty_data') }}
        template(v-else)
          external-shutter-order-items-after-sum(v-if="order.category === 'external_shutter'" :external-shutter-order-items="orderItems()" :price-type="priceType" :print-type="'full'")
          external-shutter-order-items(v-if="order.category === 'external_shutter'" :external-shutter-order-items="orderItems()" :price-type="priceType" :print-type="'full'")
          fabric-shade-order-items(v-if="order.category === 'fabric_shade'" :fabric-shade-order-items="orderItems()" :price-type="priceType" :print-type="'full'")
          fabric-shade-day-night-order-items(v-if="order.category === 'fabric_shade_day_night'" :fabric-shade-day-night-order-items="orderItems()" :price-type="priceType" :print-type="'full'")
          pleat-order-items(v-if="order.category === 'pleat'" :pleat-order-items="orderItems()" :price-type="priceType" :print-type="'full'")
          detail-order-items(v-if="order.category === 'detail'" :detail-order-items="orderItems()" :price-type="priceType" :print-type="'full'")

          .columns.is-multilinef
            .column.is-7
              automation-order-items(v-if="order.category !== 'detail'" :automation-order-items="orderItems(true)" :price-type="priceType" :print-type="'full'")
            .column.is-2
              order-information(:order="order" :price-type="priceType" )
            .column.is-3
              summary-prices-in-order(:order="order" :price-type="priceType")


</template>
<script>

import decimal from "@/helpers/decimal";
import http from "@/http";
import notify from "@/helpers/notify";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import ExternalShutterOrderItemsAfterSum from "./orderTables/external_shutters/ExternalShutterOrderItemsAfterSum.vue";
import ExternalShutterOrderItems from "./orderTables/external_shutters/ExternalShutterOrderItems.vue";
import AutomationOrderItems from "./orderTables/automations/AutomationOrderItems.vue";
import SummaryPricesInOrder from "../components/orderTables/SummaryPricesInOrder";
import OrderInformation from "./orderTables/OrderInformation";
import FabricShadeOrderItems from "./orderTables/fabric_shades/FabricShadeOrderItems.vue";
import FabricShadeDayNightOrderItems from "./orderTables/fabric_shade_day_nights/FabricShadeDayNightOrderItems.vue";
import PleatOrderItems from "./orderTables/pleats/PleatOrderItems.vue";
import DetailOrderItems from "./orderTables/details/DetailOrderItems.vue";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  name: "OrderPreviewGlobal",
  components: {
    DetailOrderItems,
    FabricShadeDayNightOrderItems,
    FabricShadeOrderItems,
    OrderInformation,
    SummaryPricesInOrder, AutomationOrderItems, ExternalShutterOrderItems, ExternalShutterOrderItemsAfterSum,
    PleatOrderItems
  },
  props: {
    active: {type: Boolean, default: false, required: true},
    orderId: {default: null, required: true},
    priceType: {default: null, required: true}
  },
  data() {
    return {
      order: null,
      isActive: this.active,
      loading: false
    };
  },
  methods: {
    downloadOrder: debounce(async function (activator, value) {
      if (
          !this.order ||
          (this.order && this.order.id !== parseInt(this.orderId))
      ) {
        try {
          this.loading = true;
          let {data} = await http.get(
              `/orders/${this.orderId}`
              +
              generateProperties(
                  [],
                  ["id", "name", "category", "status", "valuationDate", "orderDate", "number", "netPurchaseValue", "netPurchaseAutomationValue", "netSalesValue", "netSalesAutomationValue", "netPurchaseValueBeforeDiscount", "netPurchaseAutomationValueBeforeDiscount", "netSalesValueBeforeDiscount", "netSalesAutomationValueBeforeDiscount", "quantity", "automationOrderItems"]) +
              generateProperties(
                  ["customer"],
                  ["id", "type", "firstName", "lastName", "taxId", "companyName"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems"],
                  ["id", "quantity", "weight",
                    "sumSurfacesAllCombinations",
                    "netPurchaseValue", "netSalesValue", "shortDescription", "selectedCombination", "externalShutterOrderItemCombinationsCount",
                    "externalShutter",
                    "externalShutterPriceList",
                    "externalShutterArmorExternalShutterArmorColorEntry",
                    "temporaryExternalBoxColor",
                    "temporaryInternalBoxColor",
                    "externalShutterRunnerFabricShadeColorEntry",
                    "externalShutterArmorFabricShadeColorEntry"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations"],
                  ["id", "quantity", "weight", "netPurchaseValue", "netSalesValue", "hasMosquitoNet", "width", "globalPipe", "runnersHeight", "fullHeight",
                    "externalShutterBox", "externalShutterExternalShutterRunnerEntryLeft",
                    "externalShutterExternalShutterRunnerEntryRight",
                    "externalShutterDrillHoleLeft",
                    "externalShutterDrillHoleRight",
                    "externalShutterExternalShutterProtectionEntries",
                    "externalShutterAdaptation",
                    "externalShutterDriveExit",
                    "globalDrive"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "globalPipe"],
                  ["id", "symbol"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterBox"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterDrillHoleLeft"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterDrillHoleRight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterAdaption"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterDriveExit"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterExternalShutterProtectionEntries", "externalShutterProtection"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterExternalShutterRunnerEntryLeft", "externalShutterRunner"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["externalShutterOrderItems", "externalShutterOrderItemCombinations", "externalShutterExternalShutterRunnerEntryRight", "externalShutterRunner"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems"],
                  ["id", "quantity", "netPurchaseValue", "netSalesValue", "width", "fabricWidth", "glassWidth", "height", "glassHeight", "driveSide", "hasTurnableFabric", "shortDescription"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGroup"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShade"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabric"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricColor"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGroup"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalAccessoryEntry", "globalAccessory"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalBalkEntry",
                    "globalBalk"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalFixingEntry",
                    "globalFixing"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalRunnerEntry",
                    "globalRunner"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeFabricShadeAdditionalEntries"],
                  ["isEnabled"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeFabricShadeAdditionalEntries", "fabricShadeAdditional"],
                  ["id", "name", "picture", "thumbnail", "message"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeOrderItems", "globalDrive"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "globalDriveControl"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "globalRemoteControl"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems"],
                  ["id", "quantity", "netPurchaseValue", "netSalesValue", "width", "fabricWidth", "glassWidth", "height", "glassHeight", "driveSide", "hasTurnableFabric", "shortDescription"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGroup"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricDayNight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricColorDayNight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGroup"],
                  ["id", "name", "number"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalAccessoryEntry", "globalAccessory"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalBalkEntry",
                    "globalBalk"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalFixingEntry",
                    "globalFixing"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalRunnerEntry",
                    "globalRunner"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightFabricShadeDayNightAdditionalEntries"],
                  ["isEnabled"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightFabricShadeDayNightAdditionalEntries", "fabricShadeDayNightAdditional"],
                  ["id", "name", "picture", "thumbnail", "message"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "globalDrive"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "globalDriveControl"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadDayNightOrderItems", "globalRemoteControl"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems"],
                  ["id", "@id", "quantity", "netPurchaseValue", "netSalesValue", "width", "height", "glassHeight", "shortDescription"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleat"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatFabric"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatFabricColor"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatGroup"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatFabricBottom"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatFabricColorBottom"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatGroupBottom"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatGlobalAccessoryEntry", "globalAccessory"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatAdditionalString",
                    "globalRunner"], ["id", "@id", "name", "symbol"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatPleatFixingTypeEntry",
                    "pleatFixingType"], ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatGlobalRunnerEntry",
                    "globalRunner"], ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["pleatOrderItems", "pleatPleatAdditionalEntries", "pleatAdditional"],
                  ["id", "name"],
                  true
              ) +
              generateProperties(
                  ["pleatOrderItems", "pleatPleatRemoteControlTypeEntry", "pleatRemoteControlType"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["detailOrderItems"],
                  ["id", "@id", "quantity", "netPurchaseValue", "netSalesValue", "shortDescription", "detail", "detailUnit", "pieces", "quantity"],
                  true)
          );
          this.order = data;
          if (activator === "active") {
            if (this.isActive !== !!value) {
              this.isActive = !!value;
            }
          }
        } catch (err) {

          this.order = null;
          notify(
              "Nie udało się załadować danych do podglądu zamówienia",
              "danger"
          );
          this.$emit("close");
        } finally {
          this.loading = false;
        }
      } else if (activator === "active") {
        if (this.isActive !== !!value) {
          this.isActive = !!value;
        }
      }
    }, 100),
    onClose() {
      this.isActive = false;
      this.$emit("close");
    },
    prop(object, field, extraField = null) {
      if (extraField) {
        return (object && object[extraField][field]) || t.t("empty");
      }
      return (object && object[field]) || t.t("empty");
    },
    getDecimalValue(value) {
      return decimal(value);
    },
    orderItems: function (automation = false) {
      if (!this.order) {
        return [];
      }

      const items = {
        "fabric_shade": this.order.fabricShadeOrderItems,
        "fabric_shade_day_night": this.order.fabricShadeDayNightOrderItems,
        "external_shutter": this.order.externalShutterOrderItems,
        "pleat": this.order.pleatOrderItems,
        "detail": this.order.detailOrderItems
      };

      return automation ? this.order.automationOrderItems : items[this.order.category] || [];
    },
  },
  computed: {
    orderName() {
      if (!this.order) {
        return this.$t("order_positions");
      }
      return this.$t("order_positions_for", {name: this.order.name});
    },
    orderNumber() {
      if (this.order && this.order.number) {
        return orderNumberCreator(this.order.number);
      } else {
        return "";
      }
    }
  },
  watch: {
    orderId(value) {
      if (value && !isNaN(value) && this.active) {
        this.downloadOrder("id");
      }
    },
    active(value) {
      if (value && !isNaN(value) && this.active && value) {
        this.downloadOrder("active", value);
      }
    }
  }
};
</script>